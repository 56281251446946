import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from '../../api/auth';
import { RegisterType } from '../reducers/authReducer';
import { LoginValuesType } from '../reducers/userReducer';

export const login = createAsyncThunk('auth/login', async (values: LoginValuesType, thunkAPI) => {
  try {
    const data = await auth.login(values);

    return data;
  } catch (err) {
    // localStorage.clear();

    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const logout = createAsyncThunk('auth/logout', async ({}: any, thunkAPI) => {
  try {
    const data = await auth.logout();

    return data;
  } catch (err) {
    // localStorage.clear();

    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const register = createAsyncThunk('auth/register', async (values: RegisterType, thunkAPI) => {
  try {
    const data = await auth.register(values);

    return data;
  } catch (err) {
    // localStorage.clear();
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const registerWithCode = createAsyncThunk('auth/registerWithCode', async (values: string, thunkAPI) => {
  try {
    const { data } = await auth.registerWithCode(values);

    return data;
  } catch (err) {
    // localStorage.clear();

    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getUser = createAsyncThunk('auth/getUser', async ({}: any, thunkAPI) => {
  try {
    const data = await auth.getUser();

    return data;
  } catch (err) {
    // localStorage.clear();

    return thunkAPI.rejectWithValue(err.response.data);
  }
});
