import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp2 = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
const phoneRegExp3 = /^([+][0-9]{8,15})$/i;

export const validate = async (values: any, schema: any) => {
  if (typeof schema === 'function') schema = schema();
  const errs: any = { ...values };
  Object.keys(errs).forEach((el) => {
    errs[el] = '';
  });
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (e) {
    return e.inner.reduce((errors: any, error: any) => {
      errs[error.path] = error.message;
      return errs;
    }, {});
  }
};

export const registrationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email field is required'),
  name: Yup.string().required('Name field is required'),
  password: Yup.string().min(6).required('Password must be 6+ characters'),
  phone: Yup.string().matches(phoneRegExp3, 'Phone number is not valid').required(),
  companyName: Yup.string().required('Name field is required'),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Email field is required'),
  password: Yup.string().min(6).required('Password must be 6+ characters'),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required('Email field is required'),
});
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password must be 6+ characters'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
