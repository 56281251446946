import React from 'react';
import styles from './InviteCode.module.scss';
import { ReactComponent as RunningDogSvg } from '../../../../../assets/svg/runningDog.svg';
import { ReactComponent as CopySvg } from '../../../../../assets/svg/copy.svg';
import RoundedButton from '../../../../components/RoundedButton/RoundedButton';

type InviteCodeType = {
  code: string;
};

const InviteCode = ({ code }: InviteCodeType): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.internalWrapper}>
        <div className={styles.invitation}>
          <h2>Your Invitation Code</h2>
          <div className={styles.code}>
            <span>{code}</span>
          </div>
          <RoundedButton
            isActive={false}
            iconLeft={<CopySvg className={styles.copyIcon} />}
            text={'Copy code'}
            onClick={() => {
              navigator.clipboard.writeText(code);
            }}
          />
        </div>
        <RunningDogSvg className={styles.dog} />
      </div>
    </div>
  );
};

export default InviteCode;
