import React, { useState, useEffect, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  PaymentElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import RoundedButton from "../../../../components/RoundedButton/RoundedButton";
import ModalStripe from "../../../../components/ModalStripe/ModalStripe";

import { setLoadingStripe } from "../../../../../redux/reducers/stripeReducer";
import { DispatchType, StateType } from "../../../../../redux/store";
import { ReactComponent as BallSvg } from "../../../../../assets/svg/ball.svg";

import styles from "./Membership.module.scss";
import { ResponseSubscriptionsPlan } from "../../../../../redux/reducers/subscriptionsReducer";
import {
  cancelSubscription,
  resumeSubscription,
  setSubscription,
} from "../../../../../redux/actions/subscriptionActions";
import { UserType } from "../../../../../redux/reducers/userReducer";
import moment from "moment";
import { getUser } from "../../../../../redux/actions/authActions";
import { setFullAmount } from "../../../../../helpers/functions";

type MembershipType = {
  subscriptionPlans: Array<ResponseSubscriptionsPlan>;
  user: UserType;
};

const Membership = ({
  subscriptionPlans,
  user,
}: MembershipType): JSX.Element => {
  const [activePlan, setActivePlan] = useState(0);
  const [activePlanId, setActivePlanId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSubscribed, setIsSubscribe] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const indexActive =
    user.activeSubscriptions?.length &&
    subscriptionPlans.findIndex(
      (el) => el.id === user.activeSubscriptions[0]?.stripePlan
    );

  useEffect(() => {
    // if (subscriptionPlans.length) {
    //   subscriptionPlans[0]?.id && setActivePlanId(subscriptionPlans[0].id);
    // }
    if (
      user?.activeSubscriptions.length &&
      user.activeSubscriptions[0]?.stripePlan
    ) {
      setActivePlanId(user.activeSubscriptions[0].stripePlan);
      setActivePlan(indexActive);
    }
  }, [user]);

  useEffect(() => {
    if (user.subscriptionActive) {
      setIsSubscribe(true);
    }
  }, [user]);

  const dispatch = useDispatch<DispatchType>();

  const description = [
    "Unlimited clients",
    "Unlimited training types",
    "Custom app branding",
    "Custom app colours",
    "Unlimited training history",
  ];

  const getUserData = () => {
    dispatch(getUser({})).then(() =>
      setTimeout(() => setIsDisabled(false), 1000)
    );
  };

  return (
    <div className={styles.content}>
      <h2>Choose a SeriouslyDogs Membership Plan</h2>
      <ModalStripe
        isActive={showModal}
        {...{ activePlanId }}
        closeModal={() => {
          setShowModal(false);
          setIsDisabled(false);
        }}
      />
      {isSubscribed && <h3>Your subscription plan is</h3>}
      <div className={styles.plans}>
        {subscriptionPlans.map((el, idx) => {
          if (el.active) {
            return (
              <div
                key={`${idx}`}
                className={`${styles.planWrapper} ${
                  activePlanId === el.id ? styles.planWrapperActive : ""
                }`}
                onClick={() => setActivePlan(idx)}
              >
                <div
                  className={`${styles.planInternalWrapper} ${
                    activePlan === idx ? styles.activePlan : ""
                  }`}
                >
                  <div>
                    <div className={styles.title}>{el.product.name}</div>
                    <div className={styles.subTitle}>{el.interval}ly</div>
                    <div className={styles.description}>
                      {description.map((el, idx) => {
                        return (
                          <div key={`${idx}`}>
                            <p>
                              <span>
                                <BallSvg className={styles.ball} />
                              </span>
                              {el}
                            </p>
                          </div>
                        );
                      })}
                      {/*  {el.additionalInfo && <div className={styles.additionalInfo}>{el.additionalInfo}</div>} */}
                    </div>
                  </div>
                  <div className={styles.priceWrapper}>
                    <div className={styles.amount}>
                      {/* {el.currency}
                      {el.amount}/{el.interval} */}
                      {setFullAmount({
                        amount: el.amount,
                        currency: el.currency,
                        interval: el.interval,
                      })}
                    </div>
                    {/*  <p>
                    {el.currency}
                    {el.priceMonth}/month
                  </p> */}
                  </div>
                </div>
                {!!user.subscriptionValidityDate && (
                  <h3 className={styles.validDate}>
                    Your subscription is valid until:{" "}
                    {moment(user.subscriptionValidityDate).format(
                      "DD MMMM YYYY"
                    )}
                  </h3>
                )}
                {((activePlan === idx && user.onGracePeriod) ||
                  (indexActive === idx && activePlan === idx)) && (
                  <RoundedButton
                    disabled={isDisabled}
                    onClick={() => {
                      setIsDisabled(true);
                      if (
                        !isSubscribed ||
                        (isSubscribed &&
                          user.onGracePeriod &&
                          indexActive !== idx)
                      ) {
                        setShowModal(true);
                      } else {
                        user.onGracePeriod
                          ? dispatch(resumeSubscription({})).then((res) => {
                              getUserData();
                            })
                          : dispatch(cancelSubscription({})).then((res) => {
                              getUserData();
                            });
                      }
                    }}
                    text={
                      isSubscribed
                        ? user.onGracePeriod
                          ? indexActive === idx
                            ? "Resume"
                            : "Switch to this plan"
                          : "Cancel"
                        : "Switch to this plan"
                    }
                  />
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Membership;
