import { resetPassword, setNewPassword } from './../actions/userActions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { register } from '../actions/authActions';

export type RegisterType = {
  name: string;
  email: string;
  phone: string;
  showPhone: boolean;
  companyName: string;
  password: string;
  role: string;
  invite_code?: string;
};

type RestoreType = {
  status: 'success' | 'error' | '';
  restoreMessage: string;
};

type AuthStateType = {
  login: {
    email: string;
    password: string;
    isLoading: boolean;
    googleLogin: boolean;
  };
  register: RegisterType;
  restore: RestoreType;
  isLoading: boolean;
  errors: unknown | any;
};

const defaultState: AuthStateType = {
  login: {
    email: '',
    password: '',
    isLoading: false,
    googleLogin: false,
  },
  register: {
    name: '',
    email: '',
    phone: '',
    showPhone: true,
    companyName: '',
    password: '',
    role: '',
    invite_code: '',
  },
  restore: {
    status: '',
    restoreMessage: '',
  },
  isLoading: false,
  errors: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    resetRestore: (state) => {
      state.restore.restoreMessage = '';
      state.restore.status = '';
    },
  },
  extraReducers: {
    [register.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [register.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
    },
    [register.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [resetPassword.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;

      if (payload.message && payload.success) {
        state.restore.restoreMessage = payload.message;
        state.restore.status = 'success';
      }
    },
    [resetPassword.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;

      /* 
      if (payload.message && payload.status) {
        state.restore.restoreMessage = payload.message;
        state.restore.status = payload.status;
      } */
    },
    [setNewPassword.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [setNewPassword.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;

      if (payload.message && payload.success) {
        state.restore.restoreMessage = payload.message;
        state.restore.status = 'success';
      }
    },
    [setNewPassword.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;

      /* 
      if (payload.message && payload.status) {
        state.restore.restoreMessage = payload.message;
        state.restore.status = payload.status;
      } */
    },
  },
});

const authReducer = authSlice.reducer;

export const { resetRestore } = authSlice.actions;
export default authReducer;
