import React, { useState } from 'react';
import styles from './ToggleButton.module.scss';
import { FieldInputProps } from 'react-final-form';

type ToggleButtonType = {
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  active: boolean;
  inputProps?: FieldInputProps<string, HTMLElement>;
};

const ToggleButton = ({ onClick, active = false, inputProps }: ToggleButtonType): JSX.Element => {
  const [activeButton, setActive] = useState(active);

  return (
    <div className={styles.wrapper}>
      <input
        className={`${styles.toggleButton} ${activeButton ? '' : styles.disabled}`}
        type={'checkbox'}
        {...inputProps}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          onClick(e);
          setActive(!activeButton);
        }}
      />
    </div>
  );
};

export default ToggleButton;
