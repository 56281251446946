import { resetPassword } from './../actions/userActions';
import { register } from '../actions/authActions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AlertType = {
  open: boolean;
  type: 'success' | 'error';
  message: string;
  length?: number;
};

const defaultState: AlertType = {
  open: false,
  type: 'success',
  message: '',
  length: 5000,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: defaultState,
  reducers: {
    setAlert: (state, { payload }) => {
      state.open = true;
      state.type = payload.type;
      state.message = payload.message;
      state.length = +payload.time >= 0 ? payload.time : 5000;
    },
    closeAlert: (state) => {
      state.open = false;
      state.message = '';
    },
  },
  extraReducers: {
    /*  [resetPassword.pending.toString()]: (state, action) => {},
    [resetPassword.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<{ success: boolean; message: string }>
    ) => {
      state.open = true;
      state.type = 'success';
      state.message = payload.message ? payload.message : 'Success';
    },
    [resetPassword.rejected.toString()]: (state, { payload }: any) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message ? payload.message : 'Something went wrong';
      state.length = 1500;
    }, */
  },
});

const alertReducer = alertSlice.reducer;

export const { setAlert, closeAlert } = alertSlice.actions;
export default alertReducer;

export const alertShowThunk = (type: string, message: string, time?: number): any => async (dispatch: any) => {
  const alertData = {
    type: type,
    message: message,
    time,
  };
  dispatch(setAlert(alertData));
};

export const alertCloseThunk = () => async (dispatch: any) => {
  dispatch(closeAlert());
};
