import { createAsyncThunk } from "@reduxjs/toolkit";
import { subscription } from "../../api/subscription";

export const getSubscriptionsPlan = createAsyncThunk(
  "subscription/getSubscriptionsPlan",
  async ({}: any, thunkAPI) => {
    try {
      const { data } = await subscription.getSubscriptionsPlan();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async ({}: any, thunkAPI) => {
    try {
      const data = await subscription.getSubscription();
      return data?.message?.intent ? data.message.intent : data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setSubscription = createAsyncThunk(
  "subscription/setSubscription",
  async (
    value: { payment_method_id: number | string | null; plan_id: number | string },
    thunkAPI
  ) => {
    try {
      const { data } = await subscription.setSubscription(value);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async ({}: any, thunkAPI) => {
    try {
      const { data } = await subscription.cancelSubscription();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resumeSubscription = createAsyncThunk(
  "subscription/resumeSubscription",
  async ({}: any, thunkAPI) => {
    try {
      const { data } = await subscription.resumeSubscription();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
