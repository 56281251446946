import React, { useEffect } from "react";
import styles from "./LoginPage.module.scss";
import { Link, useHistory } from "react-router-dom";

import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { login, register } from "../../../../redux/actions/authActions";
import { RegisterType } from "../../../../redux/reducers/authReducer";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import RoundedButton from "../../../components/RoundedButton/RoundedButton";
import CustomField from "../../../components/CustomField/CustomField";
import { loginSchema, validate } from "../../../../helpers/validationSchema";
import { ReactComponent as DogSvg } from "../../../../assets/svg/dog.svg";
import { ReactComponent as LogoTitleSvg } from "../../../../assets/svg/logoTitle.svg";
import { StateType } from "../../../../redux/store";
import { resetErrorsState } from "../../../../redux/reducers/errorsReducer";
import Loader from "../../../components/Loader/Loader";

const LoginPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading } = useSelector((state: StateType) => state.user);
  const { error } = useSelector((state: StateType) => state.errors);

  useEffect(() => {
    if (error.message) {
      setTimeout(() => {
        dispatch(resetErrorsState());
      }, 5000);
    }
  }, [error]);

  const onSubmit = async (values: RegisterType) => {
    dispatch(login(values));
  };

  const initValues = { email: "", password: "" };

  const navigateTo = (path: string) => {
    history.push(path);
  };

  return (
    <div className={styles.wrapper}>
      <Loader isLoading={isLoading} />

      <div className={styles.logoWrapper}>
        <LogoTitleSvg />
        <DogSvg />
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initValues}
        validate={(values) => {
          return validate({ ...initValues, ...values }, loginSchema);
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <h3 className={styles.subTitle}>Woof-woof!</h3>
              <CustomField
                name="email"
                type="email"
                placeholder="Email"
                isRequired
                isLogin
                errorMessage={error.message}
              />
              <CustomField
                name="password"
                type="password"
                placeholder="Password"
                isRequired
                isLogin
              />
              <div className={styles.buttons}>
                <div>
                  <RoundedButton
                    onClick={() => {
                      form.submit();
                    }}
                    disabled={submitting || pristine}
                    text={"Log in"}
                    isLogin
                  />

                  <Link
                    className={styles.forgotLink}
                    to="/auth/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div>
                  <p className={styles.isDogTrainer}>Are you a dog trainer?</p>

                  <RoundedButton
                    onClick={() => {
                      navigateTo("/auth/sign-up");
                    }}
                    text={"Sign up"}
                    isRegister
                  />
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default LoginPage;
