import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import LoginPage from "./LoginPage/LoginPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import BaseLayout from "../../layout/BaseLayout/BaseLayout";
import HomePage from "../MainPages/HomePage/HomePage";

import { StateType } from "../../../redux/store";

const UserPages = (): JSX.Element => {
  const { role } = useSelector((state: StateType) => state.user.user);
  return (
    <>
      <Route
        path="/user/profile"
        exact
        render={() => (role === "trainer" ? <ProfilePage /> : <HomePage />)}
      />
    </>
  );
};

export default UserPages;
