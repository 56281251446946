import React from "react";
import { useSelector } from "react-redux";

import Loader from "../../../components/Loader/Loader";
import { StateType } from "../../../../redux/store";

import styles from "./HomePage.module.scss";

const HomePage = (): JSX.Element => {
  const { isLoading } = useSelector((state: StateType) => state.user);

  return (
    <div className={styles.wrapper}>
      <Loader isLoading={isLoading} notTransparent />
      <h1>
        Sorry, this page made for Dog trainers.Please use the Seriously Dog
        application instead
      </h1>
    </div>
  );
};

export default HomePage;
