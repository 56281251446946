import React, { useState, useEffect } from 'react';
import styles from './ForgotPage.module.scss';

import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../../../redux/actions/authActions';
import { RegisterType, resetRestore } from '../../../../redux/reducers/authReducer';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import RoundedButton from '../../../components/RoundedButton/RoundedButton';
import CustomField from '../../../components/CustomField/CustomField';
import {
  forgotPasswordSchema,
  registrationSchema,
  resetPasswordSchema,
  validate,
} from '../../../../helpers/validationSchema';
import { resetPassword, setNewPassword } from '../../../../redux/actions/userActions';
import { StateType } from '../../../../redux/store';
import { resetErrorsState } from '../../../../redux/reducers/errorsReducer';
import { useHistory } from 'react-router-dom';

const ForgotPage = () => {
  const [forgotError, setForgotError] = useState('');
  const [urlCode, setUrlCode] = useState('');
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { error } = useSelector((state: StateType) => state.errors);
  const {
    restore: { restoreMessage, status },
  } = useSelector((state: StateType) => state.auth);

  const urlSearchParams = new URLSearchParams(window.location.search);

  const code = urlSearchParams.get('confirm_code');

  useEffect(() => {
    return () => {
      dispatch(resetRestore());
    };
  }, []);

  useEffect(() => {
    if (status) {
      setSuccess(true);
    }
  }, [status]);

  useEffect(() => {
    if (!!code) {
      setUrlCode(code);
    }
  }, [code]);

  useEffect(() => {
    if (error.message) {
      setTimeout(() => {
        dispatch(resetErrorsState());
      }, 5000);
    }
  }, [error]);

  type ResetValuesType = { password: string; passwordConfirm: string };
  type InitValuesType = { email: string };

  const onSubmit = async (values: any) => {
    urlCode
      ? dispatch(setNewPassword({ password: values.password, confirmCode: urlCode }))
      : dispatch(resetPassword(values as { email: string }));
  };

  const initValues = { email: '' };
  const resetInitialValues = { password: '', passwordConfirm: '' };

  return (
    <div className={styles.wrapper}>
      {success ? (
        <div className={styles.successWrapper}>
          <h2>{restoreMessage}</h2>
        </div>
      ) : (
        <>
          <h1>Forgot Password</h1>
          <Form
            onSubmit={onSubmit}
            initialValues={!!urlCode ? resetInitialValues : initValues}
            validate={(values) => {
              return validate({ ...initValues, ...values }, urlCode ? resetPasswordSchema : forgotPasswordSchema);
            }}
            render={({ handleSubmit, form, submitting, pristine, values, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {!!urlCode ? (
                    <>
                      <CustomField
                        name='password'
                        type='password'
                        placeholder='Please Enter New Password'
                        label={'Password'}
                        isRequired
                        errorMessage={error.message}
                      />
                      <CustomField
                        name='passwordConfirm'
                        type='password'
                        placeholder='Please Confirm Password'
                        label={'Password Confirmation'}
                        isRequired
                        errorMessage={error.message}
                      />
                    </>
                  ) : (
                    <CustomField
                      name='email'
                      type='email'
                      placeholder='Please Enter Your Email'
                      label={'Email'}
                      isRequired
                      isForgot
                      errorMessage={error.message}
                    />
                  )}

                  <div className={styles.buttonsWrapper}>
                    <RoundedButton
                      onClick={() => {
                        form.submit();
                      }}
                      disabled={submitting || pristine}
                      text={'Reset Password'}
                    />
                  </div>
                </form>
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default ForgotPage;
