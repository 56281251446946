import React, { useState } from 'react';
import styles from './RoundedButton.module.scss';

type RoundedButtonType = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isLogin?: boolean;
  isRegister?: boolean;
  text: string;
  isActive?: boolean;
  iconLeft?: JSX.Element;
  submitType?: boolean;
};

const RoundedButton = ({
  text,
  onClick,
  disabled = false,
  isLogin = false,
  isRegister = false,
  isActive,
  iconLeft,
  submitType,
}: RoundedButtonType): JSX.Element => {
  return (
    <button
      type={submitType ? 'submit' : 'button'}
      className={`${styles.button} ${disabled ? styles.disabled : ''} ${
        isLogin || (isActive !== undefined && !isActive) ? `${styles.loginButton} ${styles.activeButton}` : ''
      } ${isRegister ? styles.registerButton : ''} `}
      {...{ disabled }}
      {...{ onClick }}
    >
      {iconLeft && <span className={styles.iconLeft}>{iconLeft}</span>} {text}
    </button>
  );
};

export default RoundedButton;
