import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUser, login, logout, register } from "../actions/authActions";
import { resetPassword } from "../actions/userActions";
import { ColorSchemeType } from "./trainerReducer";

type RoleType = "owner" | "trainer";

export type LoginValuesType = {
  email: string;
  password: string;
};

type UserType2 = {
  id: number;
  role: RoleType;
  name: string;
  email: string;
  password: string;
  phone: number;
};

export type UserType = {
  companyName: null | string;
  confirmCode: null | string;
  confirmCodeExpire: "1630956502";
  confirmedAt: null | string | number;
  email: string;
  emailVerifiedAt: null | any;
  id: number;
  name: string;
  onGracePeriod: boolean;
  phone: null | string;
  publicKey: null | string;
  rememberToken: null | string;
  role: "trainer" | "owner";
  showPhone: null | boolean;
  stripeId?: string | null;
  stripeStatus: "inactive" | "active";
  subscriptionActive: boolean;
  subscriptionValidityDate: string;
  trainerProfiles?: Array<{
    code: string;
    currency: string;
    id: number;
    image: null | string;
    scheme: null | ColorSchemeType;
    userId: number;
  }>;
  activeSubscriptions?: any;
};

type LoginResponse = {
  accessToken: string;
  tokenType: string;
  expiresIn: null | unknown;
};

type UserStateType = {
  user: UserType;
  token: string;
  isLoading: boolean;
};

const defaultState: UserStateType = {
  user: {} as UserType,
  token: "",
  isLoading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: defaultState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [login.pending.toString()]: (state, action) => {
      state.isLoading = true;
    },
    [login.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<LoginResponse>
    ) => {
      const token = payload.accessToken;

      localStorage.setItem("token", token);
      state.isLoading = false;
      state.token = token;
    },
    [login.rejected.toString()]: (state, action) => {
      localStorage.clear();
      state.user = {} as UserType;
      state.isLoading = false;
      state.token = "";
    },
    [register.pending.toString()]: (state, action) => {
      state.isLoading = true;
    },
    [register.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<LoginResponse>
    ) => {
      const token = payload.accessToken;
      localStorage.setItem("token", token);
      state.isLoading = false;
      state.token = token;
    },
    [register.rejected.toString()]: (state, action) => {
      localStorage.clear();
      state.user = {} as UserType;
      state.isLoading = false;
      state.token = "";
    },

    [logout.pending.toString()]: (state, action) => {
      state.isLoading = true;
    },
    [logout.fulfilled.toString()]: (state, { payload }) => {
      state.user = {} as UserType;
      state.token = "";
      localStorage.clear();
      state.isLoading = false;
    },
    [logout.rejected.toString()]: (state, action) => {
      localStorage.clear();
      state.user = {} as UserType;
      state.isLoading = false;
      state.token = "";
    },
    [getUser.pending.toString()]: (state, action) => {
      state.isLoading = true;
    },
    [getUser.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<UserType>
    ) => {
      state.user = payload;

      state.isLoading = false;
    },
    [getUser.rejected.toString()]: (state, action) => {
      state.user = {} as UserType;
      state.isLoading = false;
    },
  },
});

const userReducer = userSlice.reducer;

export const { setUser } = userSlice.actions;
export default userReducer;
