import { RegisterType } from '../redux/reducers/authReducer';
import { LoginValuesType } from '../redux/reducers/userReducer';
import { instance } from './api';

export const auth = {
  login: async (values: LoginValuesType) => {
    const { data } = await instance.post('/auth/login', values);
    return data;
  },
  logout: async () => {
    const { data } = await instance.get('/auth/logout');
    return data;
  },
  register: async (values: RegisterType) => {
    const { data } = await instance.post('/auth/register', values);
    return data;
  },
  registerWithCode: async (values: string) => {
    const { data } = await instance.get(`/trainerProfiles/getByCode/${values}`);
    return data;
  },
  getUser: async () => {
    const { data } = await instance.get('/auth/me');

    return data;
  },
};
