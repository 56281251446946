import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUser, login, register, registerWithCode } from '../actions/authActions';
import { resetPassword } from '../actions/userActions';

type RoleType = 'owner' | 'trainer';

export type LoginValuesType = {
  email: string;
  password: string;
};

type UserType = {
  id: number;
  role: RoleType;
  name: string;
  email: string;
  password: string;
  phone: number;
};

type LoginResponse = {
  accessToken: string;
  tokenType: string;
  expiresIn: null | unknown;
};

type ErrorsStateType = {
  error: { message: string; code?: number };
};

const initialState = { message: '', code: 0 };

const defaultState: ErrorsStateType = {
  error: initialState,
};

const errorsSlice = createSlice({
  name: 'errors',
  initialState: defaultState,
  reducers: {
    resetErrorsState: (state) => {
      state.error = initialState;
    },
  },
  extraReducers: {
    [login.rejected.toString()]: (state, { payload }) => {
      if (!!payload.error) {
        state.error.message = payload.error;
      }
    },
    [register.rejected.toString()]: (state, { payload }) => {
      if (!!payload?.message) {
        //TODO: back should change error response
        const emailIsTaken = payload.message.original?.email;
        state.error.message = emailIsTaken[0] ? emailIsTaken[0] : 'Something went wrong';
      }
    },
    [getUser.rejected.toString()]: (state, { payload }) => {},
    [registerWithCode.rejected.toString()]: (state, { payload }) => {
      if (payload) {
        state.error.message = payload.message;
      }
    },
    [resetPassword.rejected.toString()]: (state, { payload }) => {
      if (payload) {
        state.error.message = payload.message;
      }
    },
  },
});

const errorsReducer = errorsSlice.reducer;

export const { resetErrorsState } = errorsSlice.actions;
export default errorsReducer;
