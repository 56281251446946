import React from "react";
import { useDispatch, useSelector } from "react-redux";

import RoundedButton from "../../../../components/RoundedButton/RoundedButton";
import Loader from "../../../../components/Loader/Loader";

import {
  getStripeAccount,
  createStripeAccount,
} from "../../../../../redux/actions/stripeActions";
import { StateType } from "../../../../../redux/store";

import styles from "./Stripe.module.scss";

const Stripe: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: StateType) => state.stripe);
  const { user } = useSelector((state: StateType) => state.user);

  const onOpenAccount = () => {
    user?.stripeId
      ? //@ts-ignore
        dispatch(getStripeAccount({})).then((res) => {
          if (typeof res.payload === "string") {
            window.location.href = res.payload;
          }
          if (typeof res.payload === "object" && res.payload.url) {
            window.location.href = res.payload.url;
          }
        })
      : //@ts-ignore
        dispatch(createStripeAccount({})).then((res) => {
          if (typeof res.payload === "string") {
            window.location.href = res.payload;
          }
          if (typeof res.payload === "object" && res.payload.url) {
            window.location.href = res.payload.url;
          }
        });
  };

  return (
    <div className={styles.content}>
      <Loader isLoading={isLoading} />
      <div className={styles.modal}>
        <div className={styles.stateStripe}>
          {user?.stripeId ? user?.stripeStatus : "Not connected"}
        </div>

        <div className={styles.button}>
          <RoundedButton
            onClick={onOpenAccount}
            text={
              user?.stripeId
                ? user?.stripeStatus === "active"
                  ? "Open your account"
                  : "Update account"
                : "Connect stripe account"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Stripe;
