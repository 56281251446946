import React from 'react';
import styles from './Header.module.scss';
import { useLocation, Link, NavLink } from 'react-router-dom';
import { headerMenuList } from '../../../helpers/values';
import { ReactComponent as DogSvg } from '../../../assets/svg/dog.svg';
import DogImg from '../../../assets/svg/dog.svg';
import { ReactComponent as LogoTitleSvg } from '../../../assets/svg/logoTitle.svg';
import { ReactComponent as LogoutSvg } from '../../../assets/svg/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/store';
import { logout } from '../../../redux/actions/authActions';

const Header = (): JSX.Element => {
  const { token, user } = useSelector((state: StateType) => state.user);

  const dispatch = useDispatch();

  const localToken = localStorage.getItem('token');

  const isLogined = token || localToken;

  const userImgLink = (user?.trainerProfiles && user?.trainerProfiles[0]?.image) || DogImg;

  return (
    <header className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <Link to={'/user/profile'}>
          <DogSvg />
          <LogoTitleSvg />
        </Link>
      </div>
      <div className={styles.links}>
        {!isLogined ? (
          headerMenuList.map((el, index) => {
            return (
              <NavLink className={styles.link} activeClassName={styles.activeLink} key={`${index}`} to={el.url}>
                {el.name}
              </NavLink>
            );
          })
        ) : (
          <>
            <div className={styles.userImg}>
              <img src={userImgLink} alt={'userImg'} />
            </div>
            <span className={styles.name}>{user.companyName && user.companyName.length > 10
                ? `${user.companyName?.substr(0, 15)}...`
                : user.companyName || 'Log Out'}
            </span>
            <a
              onClick={() => {
                dispatch(logout({}));
              }}
              className={styles.link}
            >
              <LogoutSvg />
            </a>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
