import React from 'react';
import styles from './CustomField.module.scss';
import { Field } from 'react-final-form';
import { ReactComponent as EmailSvg } from '../../../assets/svg/email.svg';

type CustomFieldType = {
  name: string;
  type: string;
  placeholder: string;
  label?: string;
  isRequired?: boolean;
  isLogin?: boolean;
  isForgot?: boolean;
  errorMessage?: string;
};

const CustomField = ({
  name,
  type,
  placeholder,
  label,
  isRequired = false,
  isLogin = false,
  isForgot = false,
  errorMessage,
}: CustomFieldType): JSX.Element => {
  const inputTextHandler = (value: string) => {
    return type === 'password' ? '*'.repeat(+value.length) : value;
  };
  return (
    <Field {...{ name }} component='input' {...{ type }} {...{ placeholder }}>
      {({ input, meta }) => {
        const autoComplete = type === 'password' && !isLogin ? { autoComplete: 'new-password' } : {};
        return (
          <div
            className={`${styles.inputWrapper} ${isLogin ? styles.loginInput : ''} ${
              isForgot ? styles.forgotInput : ''
            }`}
          >
            {label && <label>{label}</label>}
            <input
              {...input}
              {...{ placeholder }}
              {...autoComplete}
              value={type === 'tel' ? `+${input.value.replace(/[^0-9]/g, '')}` : input.value}
            />
            {isForgot && (
              <div className={styles.forgotIcon}>
                <EmailSvg />
              </div>
            )}
            {type === 'password' && !isLogin && (
              <span className={styles.passwordText}>{'*'.repeat(+input.value.length)}</span>
            )}
            {((isRequired && meta.error && meta.touched) || errorMessage) && (
              <span className={styles.error}>{meta.error || errorMessage}</span>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default CustomField;
