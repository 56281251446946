import { RegisterType } from '../redux/reducers/authReducer';
import { LoginValuesType } from '../redux/reducers/userReducer';
import { instance } from './api';

export const user = {
  resetPassword: async (values: { email: string }) => {
    const { data } = await instance.post('/user/resetPassword', values);
    return data;
  },
  setNewPassword: async (values: { password: string; confirmCode: string }) => {
    const { data } = await instance.put(`/user/setNewPassword?confirm_code=${values.confirmCode}`, {
      password: values.password,
    });
    return data;
  },
};
