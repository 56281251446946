import React from 'react';
import styles from './TermsOfUse.module.scss';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Seriously Dogs Privacy Policy</h1>

      <p className={styles.italic}>Effective November 1, 2021</p>

      <p className={styles.paragraph}>
        At Seriously Dogs, we recognise the importance of privacy. We want you to feel confident using our services, and
        this Privacy Policy is to help you understand and describe how we collect, use, and disclose your information.
      </p>
      <p className={styles.paragraph}>
        We will use a few terms throughout this document to describe various products and roles. All of the text, data,
        information, software, graphics, photographs and more that we and our affiliates may make available to you, plus
        our Services and any services, plug-ins, software, or applications that we may provide all together constitute
        Seriously Dogs &quot;Services.&quot; Seriously Dogs &quot;Users&quot; are dog professionals who have created a
        Seriously Dogs account, and &quot;Seriously Dogs Owners&quot; are people who have registered with Seriously Dogs
        to use services offered by a Seriously Dogs User.
      </p>

      <strong>Information We Collect</strong>

      <p className={styles.paragraph}>
        We collect information about you directly from you and automatically through your use of our Services. We may
        combine information automatically collected with other information that we have collected about you, including
        from publicly available sources.
      </p>
      <p className={styles.paragraph}>
        To help you protect yourself and your information, we encourage you to provide only that necessary information
        for using our Services.
      </p>
      <p className={styles.paragraph}>
        Seriously Dogs is not directed to children under thirteen (13) years of age, and we do not knowingly collect
        Personal Information from children under 13. If we discover that a child under 18 has provided us with Personal
        Information, we will promptly delete such Personal Information from our systems.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Information You Provide Voluntarily</span>
      </p>
      <p className={styles.paragraph}>
        Seriously Dogs collects the following information, either directly from Seriously Dogs Users, Owners, or
        Viewers, or through third parties regarding Users, Owners, or Viewers.
      </p>
      <p className={styles.paragraph}>
        A Seriously Dogs User, Owner, or a third-party may voluntarily give us certain information. This can include
        names, email addresses, phone numbers; email addresses of other people; veterinary information; and any other
        information provided to us or as required by the Seriously Dogs User upon using their service. If you receive an
        invitation from a Seriously Dogs User but do not wish to become a Seriously Dogs Owner, please contact the
        Seriously Dogs User who sent the invitation through other means to use their services.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Billing Information</span>
      </p>
      <p className={styles.paragraph}>
        Our third-party payment processors will collect and store your billing address and credit card information if
        you are a Seriously Dogs User. We store the last four digits of your credit card number, card type, and
        expiration date.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Information Collected Automatically</span>
      </p>
      <p className={styles.paragraph}>
        Like many websites, we and our Service Providers use cookies, web beacons, and other technologies to receive and
        store certain types of information when you interact with us through your computer or mobile device subject to
        your opt-out preferences (see Your Rights and Choices section below). Using these technologies helps us
        customise your experience with our Services, improve your experience, and tailor marketing messages. Here are
        some of the types of information we collect:
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Log &amp; Device Data</span>
      </p>
      <p className={styles.paragraph}>
        When you use Seriously Dogs, our servers automatically record information (&quot;log data&quot;), including
        information that your browser sends whenever you use our Services. This log data may include the web address you
        came from or are going to, your device model, operating system, browser type, unique device identifier, IP
        address, mobile network carrier, and time zone or location. Whether we collect some or all of this information
        often depends on what type of device you&apos;re using and its settings. For example, different types of
        information are available depending on whether you&apos;re using a Mac or PC, an iPhone or an Android phone. To
        learn more about what information your device makes available to us, please check the policies of your device
        manufacturer or software provider.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Cookie Data</span>
      </p>
      <p className={styles.paragraph}>
        Depending on how you access our services and subject to your opt-out preferences, we may use &quot;Cookies&quot;
        (a small text file sent by your computer each time you visit our Services, unique to your Seriously Dogs account
        or your browser) or similar technologies to record log data. When we use Cookies, we may use &apos;session&apos;
        Cookies (that last until you close your browser) or &apos;persistent&apos; Cookies (that last until you or your
        browser deletes them). For example, we may use Cookies to keep you logged into Seriously Dogs. Some of the
        cookies we use are associated with your Seriously Dogs account (including personal information about you, such
        as the email address you gave us), and other Cookies are not.
      </p>

      <strong>Other Web Site Analytics Services</strong>

      <p className={styles.paragraph}>
        Subject to your opt-out preferences (see &apos;Your Rights and Choices&apos; below), we use third-party Service
        Providers such as Google Analytics to provide certain analytics and Viewer interactions services to Seriously
        Dogs in connection with the operation of our Services, including the collection and tracking of certain data and
        information regarding the characteristics and activities of visitors to Seriously Dogs. You may opt-out of
        relevant cookies using opt-out features on their respective Services.
      </p>

      <strong>How We Use Your Information</strong>

      <p className={styles.paragraph}>We may use information that we collect about you, including Personal Data, to:</p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Provide the Seriously Dogs Service</span>
      </p>
      <p className={styles.paragraph}>
        The information provided by our Seriously Dogs Users is used to provide our Services, including facilitating
        bookings, managing your account, responding to your inquiries and other customer service and support purposes.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Understand and improve our products</span>
      </p>
      <p className={styles.paragraph}>
        We will perform research and analysis about your use of, or interest in, our products, services, or content, or
        products, services or content offered by others. We do this to help make our products better and develop new
        products.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Service-related communications</span>
      </p>
      <p className={styles.paragraph}>
        We may send you service and administrative emails to ensure the service is working properly. We will also notify
        you regarding your bookings. These notifications are considered part of the service.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Promotional</span>
      </p>
      <p className={styles.paragraph}>
        Subject to your opt-out preference, we may send you emails about new product features or other news about
        Seriously Dogs or on topics we think would be relevant to you. You may opt-out of receiving these communications
        at any time. For Seriously Dogs Owners, please be assured that we do not use the email addresses you enter to
        schedule a meeting with a Seriously Dogs User to send any direct marketing.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Responding to your requests</span>
      </p>
      <p className={styles.paragraph}>We will use your data to respond to your questions or comments.</p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Administrative</span>
      </p>
      <p className={styles.paragraph}>
        We may contact you to inform you about changes in your services, our service offering, and other important
        service-related notifications, such as policy changes or security or fraud notifications.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Protecting Rights and Interests</span>
      </p>
      <p className={styles.paragraph}>
        We will use your information to protect our rights and interests and the rights and interests of our Users and
        any other person and enforce this Policy or our Terms of Use.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Legal Compliance</span>
      </p>
      <p className={styles.paragraph}>
        We may use your information to comply with applicable legal or regulatory obligations, including requests from
        law enforcement or other governmental authorities, or in legal proceedings involving Seriously Dogs.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Other</span>
      </p>
      <p className={styles.paragraph}>
        We also may use your information to manage our business or perform functions as otherwise described to you at
        the time of collection, subject to your consent.
      </p>

      <strong>With Whom We May Share Your Information</strong>

      <p className={styles.paragraph}>
        We do not sell your information to third parties or disclose it in exchange for money or other valuable
        consideration. We do not share your Personal Data with others except as indicated within this Policy or when we
        inform you and give you an opportunity to opt-out of having your Personal Data shared.
      </p>
      <p className={styles.paragraph}>
        We will never use Owner data to send direct marketing via emails, SMS, physical mailings, or other similar
        communication channels to advertise or promote the use of our product and services or those of a third party.
      </p>
      <p className={styles.paragraph}>
        We may share information we collect about you, including Personal Data, in the following ways:
      </p>
      <p className={styles.paragraph}>
        With third-party Service Providers, agents, contractors, or government entities.
      </p>
      <p className={styles.paragraph}>
        We use other companies, agents or contractors (&quot;Service Providers&quot;) to perform services on our behalf
        or to assist us with providing services to you:
      </p>
      <p className={styles.paragraph}>
        We may engage Service Providers to process credit card transactions or other payment methods. We may also engage
        Service Providers to provide services such as monitoring and developing Seriously Dogs services; aiding in
        communications, infrastructure, IT services; customer service; debt collection; analysing and enhancing data.
        These Service Providers may have access to your personal or other information to provide these functions. In
        addition, some of the above-listed types of information that we request may be collected by third-party Service
        Providers on our behalf.
      </p>
      <p className={styles.paragraph}>
        We may share information with Service Providers and government entities for legal, security, and safety
        purposes. This includes sharing information in order to enforce policies or contracts, address security
        breaches, and assist in the investigation of fraud, security issues, or other concerns.
      </p>
      <p className={styles.paragraph}>
        We require Service Providers to agree to take reasonable steps to keep the Personal Data that we provide to them
        secure. We do not authorise them to use or disclose your Personal Data except in connection with providing their
        services.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Affiliates</span>
      </p>
      <p className={styles.paragraph}>
        We may disclose information to current or future affiliates or subsidiaries for research, marketing, and other
        purposes consistent with this Privacy Policy.
      </p>
      <p className={styles.paragraph}>
        We may share your data if we believe that disclosure is reasonably necessary to comply with a law, regulation,
        legal, or governmental request; to respond to a subpoena, court order, warrant, or other legal processes; to
        enforce applicable Terms of Use or this Policy, including investigation of potential violations thereof; to
        protect the safety, rights, or property of the public, any person, or Seriously Dogs; to detect, prevent, or
        otherwise address, security or technical issues, illegal, or suspected illegal activities (including fraud); or
        as evidence in litigation in which we are involved, as part of a judicial or regulatory proceeding.
      </p>
      <p className={styles.paragraph}>
        If you are using Seriously Dogs as a paid member of an organisation or using your organisation&apos;s email
        domain (thereby representing yourself as a member of the organisation), we may share your email address and
        information with an authorised agent of your company upon request for them to administer the account for the
        company.
      </p>

      <strong>Your Rights and Choices</strong>

      <p className={styles.paragraph}>
        <span className={styles.underline}>Account</span>
      </p>
      <p className={styles.paragraph}>
        You may contact us to request information about the personal data we have collected from you and to request the
        correction, modification or deletion of such Personal Data. We will do our best to honour your requests subject
        to any legal and contractual obligations. If you would like to make a request, cancel your account or request we
        delete or no longer use your account information to provide you with our Services, contact us at
        info@seriously-dogs.com or the address set forth at the end of this Policy. Subject to applicable law, we will
        retain and use your account information only as necessary to comply with our legal obligations, resolve
        disputes, and enforce our agreements.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Email</span>
      </p>
      <p className={styles.paragraph}>
        As described above, if you do not wish to receive promotional emails from us, you may opt-out at any time by
        following the opt-out link contained in the email itself. Please note that it may take up to ten (10) days to
        process your request. Please also note that if you opt-out of receiving marketing communications from us, we may
        continue to send service-related emails that are not available for opt-out. If you do not wish to receive any
        service-related emails from us, you have the option to delete your account.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Cookies</span>
      </p>
      <p className={styles.paragraph}>
        You may also refrain from providing or may withdraw your consent for cookies via your browser settings. Your
        browser&apos;s help function should contain instructions on how to set your computer to accept all cookies,
        notify you when a Cookie is issued, or not receive cookies at any time. Please keep in mind that Cookies are
        required to authenticate Seriously Dogs Users and perform some actions within Seriously Dogs (such as to pay for
        a session as an Owner via Seriously Dogs), so to use the Services, some Cookies are often required. You may also
        manage the use of targeting, performance, and functional cookies on these Services by clicking the Cookie
        Settings link located on the footer of this page.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Additional Rights</span>
      </p>
      <p className={styles.paragraph}>
        Subject to local law, you may have additional rights under the laws of your jurisdiction regarding your personal
        data, such as the right to complain to your local data protection authority.
      </p>

      <strong>Security and Storage of Information</strong>

      <p className={styles.paragraph}>
        We have taken reasonable steps to help protect the personal information we collect.
      </p>
      <p className={styles.paragraph}>
        Seriously Dogs takes the security of your data very seriously. We work hard to protect the data that you provide
        from loss, misuse, and unauthorised access or disclosure. Given the nature of communications and information
        processing technology, there is no guarantee that personal data will be absolutely safe from access, alteration,
        or destruction by a breach of any of our physical, technical, and managerial safeguards.
      </p>
      <p className={styles.paragraph}>
        You should take steps to protect against unauthorised access to your device and account by, among other things,
        choosing a unique and complex password that nobody else knows or can easily guess and keeping your log-in and
        password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on
        your account via unauthorised password activity.
      </p>
      <p className={styles.paragraph}>
        We retain the personal data we collect for so long as is reasonably necessary to fulfil the purposes for which
        the data was collected, to perform our contractual and legal obligations, and for any applicable statute of
        limitations periods for the purposes of bringing and defending claims.
      </p>

      <strong>Third-Party Links</strong>

      <p className={styles.paragraph}>
        Our Services may contain links to third-party Services and applications. Subject to your opt-out preferences
        (see Your Rights and Choices), we may also use third-party advertisers, ad networks, and other advertising,
        marketing, and promotional companies, to serve advertisements on our Services. This Policy does not govern any
        access to and use of such linked Services and applications but instead is governed by the privacy policies of
        those third parties. We do not endorse these parties, their content, or any products and services they offer,
        and we do not endorse these parties, their content, or any products and services they offer. We are not
        responsible for the information practices of such third-party Services or applications.
      </p>

      <strong>Users Outside the USA</strong>

      <p className={styles.paragraph}>Our application and database servers are located in the United Kingdom.</p>
      <p className={styles.paragraph}>
        If you are an individual located in the European Economic Area, the United States, Canada or another
        jurisdiction outside of the United Kingdom with laws and regulations governing personal data collection, use,
        and disclosure that differ from British laws, please be aware that information we collect (including through the
        use of methods such as cookies and other web technologies) will be processed and stored in the United Kingdom or
        in other countries where we or our third-party Service Providers have operations. By submitting your personal
        data to Seriously Dogs and using Seriously Dogs, you expressly consent to have your personal data transferred
        to, processed, and stored in the United Kingdom or another jurisdiction that may not offer the same level of
        privacy protection as those in the country where you reside or are a citizen.
      </p>
      <p className={styles.paragraph}>
        In connection with the operation of its Services, Seriously Dogs may transfer your personal data to various
        locations, which may include locations both inside and outside of the European Economic Area. We process
        personal data submitted relating to individuals in Europe via the Standard Contractual Clauses.
      </p>

      <strong>Do Not Track</strong>

      <p className={styles.paragraph}>
        We do not currently recognise or respond to browser-initiated Do Not Track signals as there is no consistent
        industry standard for compliance.
      </p>

      <strong>Dispute Resolution</strong>

      <p className={styles.paragraph}>
        Details regarding Seriously Dogs&apos; dispute resolution process are available in our Terms of Use.
      </p>

      <strong>Changes to this Policy</strong>

      <p className={styles.paragraph}>
        This Policy is current as of the Effective Date set forth above. This Policy may change if there is a material
        change to how information is handled at Seriously Dogs or to clarify our Policy or adjust clerical errors. We
        will post them on this page if any changes are made, so please check back periodically. If you continue to use
        Seriously Dogs after those changes are in effect, you agree to the revised Policy.
      </p>

      <strong>Contacting Us</strong>

      <p className={styles.paragraph}>
        If you have any questions or comments about this Privacy Policy, please contact us at{' '}
        <a href='mailto:info@seriously-dogs.com'>
          <span className='s4'>info@seriously-dogs.com</span>
        </a>
        .
      </p>
      <p className='p5'>
        <br />
      </p>
      <p className='p6'>
        <span className='s6'>Privacy Policy for California Residents</span>
      </p>

      <p className={styles.italic}>Effective November 1, 2021</p>

      <p className={styles.paragraph}>
        This Privacy Policy for California Residents (&quot;California Policy&quot;) supplements the Seriously Dogs
        Privacy Policy. It applies solely to Seriously Dogs Users, Viewers, and Owners who live in the State of
        California (&quot;California Residents&quot;). Seriously Dogs adopts this Policy to comply with the California
        Consumer Privacy Act of 2019 and any subsequent amendments (&quot;CCPA&quot;). All terms used in this California
        Policy have the same meaning as when used in the CCPA.
      </p>

      <strong>Information Seriously Dogs May Collect Regarding California Residents</strong>

      <p className={styles.paragraph}>
        Seriously Dogs collects information that identifies, relates to, describes, references, is capable of being
        associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device
        (&quot;Personal Information&quot;). In particular, our Services may have collected the following categories of
        Personal Information from California Resident Users, Owners, and Viewers within the last twelve months, to
        establish, maintain, and support the services that we provide through our Services:
      </p>
      <ol className='ol1'>
        <li className='li4'>Identifiers: real name; alias; email address; internet protocol address.</li>
        <li className='li4'>
          Personal Information categories listed in Cal. Civ. Code Sec. 1798.80(e): Real name; phone number.
        </li>
        <li className='li4'>Commercial information: Purchase records regarding Seriously Dogs products.</li>
        <li className='li4'>
          Internet or similar network activity: Data on Viewers&apos; interaction with the Seriously Dogs Services.
        </li>
      </ol>
      <p className={styles.paragraph}>
        Seriously Dogs obtains the categories of personal information listed above from the following categories of
        sources:
      </p>
      <ul className='ul1'>
        <li className='li4'>Directly from our Users and Owners.</li>
        <li className='li4'>Indirectly from our Users and Owners.</li>
        <li className='li4'>Directly and indirectly from activity on our Services.</li>
        <li className='li4'>From third parties that interact with us in connection with the services we perform.</li>
      </ul>

      <strong>How California Resident Personal Information May Be Used</strong>

      <p className={styles.paragraph}>
        We may use or disclose the Personal Information we collect for one or more of the following business purposes:
      </p>
      <ul className='ul1'>
        <li className='li4'>To fulfil or meet the reason for which the information is provided.</li>
        <li className='li4'>To provide you with information or services that you request from us.</li>
        <li className='li4'>
          To provide you with email alerts and other Policies concerning our services or news that may be of interest to
          you.
        </li>
        <li className='li4'>
          To carry out our obligations and enforce our rights arising from any contracts entered into between you and
          us, including for billing and collections.
        </li>
        <li className='li4'>To improve our Services.</li>
        <li className='li4'>For testing, research, analysis and service development.</li>
        <li className='li4'>As necessary or appropriate to protect Seriously Dogs and our Users.</li>
        <li className='li4'>
          To respond to law enforcement requests and as required by applicable law, court order, or governmental
          regulations.
        </li>
      </ul>
      <p className={styles.paragraph}>
        <span className={styles.underline}>Sharing Personal Information</span>
      </p>
      <p className={styles.paragraph}>
        We may disclose your Personal Information to a third party for business purposes. When we disclose Personal
        Information for a business purpose, we enter a contract that describes the purpose and requires the recipient to
        keep that Personal Information confidential and not use it for any purpose except performing your contract.
      </p>
      <p className={styles.paragraph}>
        We disclose your Personal Information for a business purpose to the following categories of third parties:
      </p>
      <ul className='ul1'>
        <li className='li4'>Service Providers.</li>
        <li className='li4'>
          Third parties to whom you or your agents authorise us to disclose your Personal Information in connection with
          the services we provide to you.
        </li>
        <li className='li4'>Government representatives as required by law.</li>
      </ul>
      <p className={styles.paragraph}>
        In the preceding twelve (12) months, we have not sold any Personal Information.
      </p>

      <strong>Access to Specific Information and Data Portability Rights</strong>

      <p className={styles.paragraph}>
        You have the right to request that we disclose certain information about our collection and use of your Personal
        Information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will
        disclose to you:
      </p>
      <ul className='ul1'>
        <li className='li4'>The categories of Personal Information we collected about you.</li>
        <li className='li4'>The categories of sources for the Personal Information we collected about you.</li>
        <li className='li4'>Our business or commercial purpose for collecting or selling that Personal Information.</li>
        <li className='li4'>The categories of third parties with whom we share that Personal Information.</li>
        <li className='li4'>The specific pieces of Personal Information that we have collected about you.</li>
      </ul>

      <strong>Deletion Request Rights</strong>

      <p className={styles.paragraph}>
        You have the right to request that we delete any of your personal information collected from you and retained,
        subject to certain exceptions. All deletion requests will be managed in accordance with Seriously Dogs Deletion
        Procedures. Deletion requests should be sent to info@seriously-dogs.com. Once we receive and confirm your
        verifiable request, we will delete (and direct our Service Providers to delete) your Personal Information from
        our records unless an exception applies.
      </p>
      <p className={styles.paragraph}>
        We may deny your deletion request if retaining the information is necessary for us or our service providers to:
      </p>
      <ul className='ul1'>
        <li className='li4'>
          Complete the transaction for which we collected the Personal Information, provide a service that you
          requested, take actions reasonably anticipated within the context of our ongoing business relationship with
          you, or otherwise perform our contract with you.
        </li>
        <li className='li4'>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute
          those responsible for such activities.
        </li>
        <li className='li4'>
          Debug software to identify and repair errors that impair existing intended functionality.
        </li>
        <li className='li4'>Comply with the California Electronic Communications Privacy Act.</li>
        <li className='li4'>
          Enable solely internal uses reasonably aligned with consumer expectations based on your relationship with us.
        </li>
        <li className='li4'>Comply with a legal obligation.</li>
        <li className='li4'>
          Otherwise lawfully use that information in compatibility with the context in which you provided it.
        </li>
      </ul>

      <strong>Exercising Access, Data Portability, and Deletion Rights</strong>

      <p className={styles.paragraph}>
        To exercise the access, data portability, and deletion rights described above, please submit a verifiable
        consumer request to us by reaching out to info@seriously-dogs.com. Only you or a person registered with the
        California Secretary of State that you authorise to act on your behalf may make a verifiable consumer request
        related to your Personal Information. You may also make a verifiable consumer request on behalf of your minor
        child. You may only make a verifiable California Resident request for access or data portability twice within 12
        months.
      </p>
      <p className={styles.paragraph}>The request must:</p>
      <ul className='ul1'>
        <li className='li4'>
          Provide sufficiently detailed information to allow Seriously Dogs to reasonably verify that you are the person
          to whom the requested Personal Information pertains or their authorised representative.
        </li>
        <li className='li4'>Include sufficient detail to allow us to fully understand, evaluate, and respond to it.</li>
        <li className='li4'>
          We cannot respond to your request or provide you with Personal Information if we cannot verify your identity
          or authority to make the request and confirm the Personal Information relates to you.
        </li>
        <li className='li4'>Making a verifiable consumer request does not require you to create an account with us.</li>
        <li className='li4'>
          Seriously Dogs will only use Personal Information provided in a verifiable consumer request to verify the
          requestor&apos;s identity or authority to make the request.
        </li>
      </ul>

      <strong>Response Timing and Format</strong>

      <p className={styles.paragraph}>
        We will attempt to respond to California Resident requests in as timely a fashion as possible. In the event of
        delays over 60 days, we will inform you of the reason and extension period in writing. If you have an account
        with us, we will deliver our written response to that account. Any disclosures we provide will only cover the 12
        months preceding the verifiable receipt of a California Resident&apos;s request. The response we provide will
        explain why we cannot comply with a request, if applicable.
      </p>
      <p className={styles.paragraph}>
        We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive,
        repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before fulfilling your request.
      </p>

      <strong>Non-Discrimination</strong>

      <p className={styles.paragraph}>
        Following the CCPA&apos;s requirements, we will not discriminate against you for exercising any of your CCPA
        rights.
      </p>

      <strong>Changes to Our Privacy Policy</strong>

      <p className={styles.paragraph}>
        We reserve the right to amend this Privacy Policy at our discretion and at any time and will notify you by email
        or through a notification on our website.
      </p>

      <strong>Contact Information</strong>

      <p className={styles.paragraph}>
        If you have any questions or comments about our Privacy Policy, how we collect and use your Personal
        Information, your choices and rights regarding such use, or wish to exercise your rights under California law,
        please do not hesitate to contact us at: info@seriously-dogs.com
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
