import { createAsyncThunk } from '@reduxjs/toolkit';
import { trainer } from '../../api/trainer';

export const getTrainerProfile = createAsyncThunk('trainer/getTrainerProfile', async ({}: any, thunkAPI) => {
  try {
    const { data } = await trainer.getTrainer();
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
