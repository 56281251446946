import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import AuthPage from './views/pages/AuthPages/AuthPages';
import BaseLayout from './views/layout/BaseLayout/BaseLayout';
import StaticPages from './views/pages/StaticPages/StaticPages';
import HomePage from './views/pages/MainPages/HomePage/HomePage';
import AuthPages from './views/pages/AuthPages/AuthPages';
import { useDispatch, useSelector } from 'react-redux';
import { StateType, DispatchType } from './redux/store';
import UserPages from './views/pages/AuthPages/UserPages';
import Alert from './views/components/Alert/Alert';
import { closeAlert } from './redux/reducers/alertReducer';
import { getTrainerProfile } from './redux/actions/trainerActions';
import { getUser, logout } from './redux/actions/authActions';
import Modal from './views/components/Modal/Modal';

function App() {
  const history = useHistory();
  const location = useLocation();
  const route = location.pathname;
  const dispatch = useDispatch<DispatchType>();

  const { token, user } = useSelector((state: StateType) => state.user);
  const { open, type, message, length } = useSelector((state: StateType) => state.alert);

  const { trainer } = useSelector((state: StateType) => state.trainer);
  const [showAlert, setShowAlert] = useState(false);

  const localToken = localStorage.getItem('token');

  const staticPagesRoutes = ['/about-us', '/terms-of-use', '/privacy-policy'];

  /*   useEffect(() => {
    if (open) {
      setShowAlert(true);

      setTimeout(() => {
        dispatch(closeAlert());
        setShowAlert(false);
      }, length);
    }
  }, [open]);
 */
  useEffect(() => {
    if (!!token || !!localToken) {
      dispatch(getUser({}))
        .then(() => {
          dispatch(getTrainerProfile({}));
        })
        .catch(() => dispatch(logout({})));
    }
    if(token || localToken){
      history.push("/user/profile");
    }
  }, [token, localToken]);

  useEffect(() => {
    if (trainer.code && user.email) {
      const isStaticPageRoute = staticPagesRoutes.includes(route);

      if (user.role === 'trainer' && !isStaticPageRoute) {
        history.push('/user/profile');
      } else if (user.role === 'owner') {
        setShowAlert(true);
        localStorage.clear();
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      }
    }
  }, [user, trainer]);

  return (
    <>
      {/*  {showAlert && <Alert status={type} text={message} />} */}
      <Switch>
        <BaseLayout>
          <AuthPages />
          <UserPages />
          <StaticPages />
          <Modal isActive={showAlert} />

          {/* <Redirect from={'/'} to={'/auth/login'} /> */}
          {/* <Redirect to='/auth/login' />*/}

          {/* <Route
          path={'*'}
          component={() => {
            return <Redirect to={'/auth/login'} />;
          }}
        />
        <Route
          path='/auth'
          component={() => {
            return <Redirect to={'/auth/login'} />;
          }}
        /> */}
        </BaseLayout>
      </Switch>
    </>
  );
}

export default App;
