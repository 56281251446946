import React from 'react';
import styles from './TermsOfUse.module.scss';

const TermsOfUsePage: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Seriously Dogs: Dog Trainers Business Suite</h1>

      <strong>Terms of Use</strong>

      <p className={styles.italic}>Effective 1 January 2022</p>
      <p className={styles.paragraph}>
        Thanks for using
        <strong> Seriously Dogs: Dog Trainers Business Suite </strong>( "Services " or "Application "). These Terms of
        Use ( "Terms " or "Agreement ") are a legal agreement between you and Seriously Dogs ( "Seriously Dogs, " "we, "
        "our, " or "us ") and govern your use of all the text, data, information, software, graphics, photographs and
        more that we and our affiliates may make available to you (all of which we refer to as "Documentation "),
        including our websites and any services, plug-ins, software or other Downloadable Tools (as defined below) that
        we may provide through any of our websites or in application stores. Seriously Dogs "Users " are the people who
        have created a Seriously Dogs account. Seriously Dogs "Owners " are people who have registered to schedule a
        meeting with a Seriously Dogs User. A Seriously Dogs "Viewer " is a person who downloads the Seriously Dogs
        Application but may or may not be a Seriously Dogs User or Seriously Dogs Owner.
      </p>
      <p className={styles.paragraph}>
        If you are entering into these Terms on behalf of a company or other organisation, you hereby represent and
        warrant that you are authorised to enter into these Terms on behalf of such company or other organisation (
        "Authorised User ").
      </p>
      <p className={styles.paragraph}>
        READ THESE TERMS CAREFULLY BEFORE USING THIS APPLICATION. USING THESE SERVICES INDICATES THAT YOU HAVE BOTH READ
        AND ACCEPT THESE TERMS. YOU CANNOT USE THESE SERVICES IF YOU DO NOT ACCEPT THESE TERMS. THESE TERMS CONTAIN A
        DISPUTE RESOLUTION AND ARBITRATION PROVISION, INCLUDING A CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS UNDER
        THESE TERMS AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH SERIOUSLY DOGS.
      </p>
      <p className={styles.paragraph}>
        IF YOU ARE A SERIOUSLY DOGS USER, AUTHORISED USER, VIEWER, OR OWNER LOCATED IN THE EUROPEAN ECONOMIC AREA,
        SWITZERLAND, OR UNITED KINGDOM, THE FOLLOWING PRIVACY POLICY ACCOMPANIES THESE TERMS AND SETS FORTH OTHER TERMS
        OF OUR AGREEMENT THAT APPLY SOLELY TO THE EXTENT ANY INFORMATION YOU PROVIDE TO SERIOUSLY DOGS INCLUDES PERSONAL
        DATA OF INDIVIDUALS LOCATED IN THE EUROPEAN ECONOMIC AREA, SWITZERLAND, AND THE UNITED KINGDOM.
      </p>

      <strong>Scope</strong>
      <p className={styles.paragraph}>
        Seriously Dogs&apos; Services facilitate the online scheduling of appointments, note-taking and client
        relationship management. You may access and use the Services only in accordance with these Terms. Please note
        that these Terms govern your use of the Services, including, but not limited to, the process through which your
        Owners schedule appointments with you.
      </p>
      <p className={styles.paragraph}>
        When using Services, you are subject to any agreements, policies or guidelines applicable to such features that
        may be posted from time to time. All such agreements, policies or guidelines, including the Privacy Policy, are
        hereby incorporated by reference into these Terms.
      </p>

      <strong>General Use</strong>

      <p className={styles.paragraph}>
        You are invited to use our Services for your individual purposes and not to resell ( "Permitted Purposes ").
      </p>
      <p className={styles.paragraph}>
        Subject to these Terms, we grant you a limited, personal, non-exclusive and non-transferable license to use and
        display the Documentation and a limited, personal, non-exclusive and non-transferable right to access and use
        the Service following the set-up of a Free Account or Paid Account as set forth below. You have no other rights
        in the Service and shall not use the Services for any purposes beyond the scope of the access granted in this
        Agreement. User shall not at any time, directly or indirectly, and to the extent User is a company or other
        organisation, permit any Authorised Users to: (i) copy, modify, or create derivative works of the Services or
        Documentation, in whole or in part; (ii) rent, lease, lend, sell, license, sublicense, assign, distribute,
        publish, transfer, or otherwise make available the Services or Documentation; (iii) reverse engineer,
        disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any software component
        of the Services, in whole or in part; (iv) remove any proprietary notices from the Services or Documentation; or
        (v) knowingly use the Services or Documentation in any manner or for any purpose that infringes,
        misappropriates, or otherwise violates any intellectual property right or other right of any person, or that
        violates any applicable law. If you make copies of any portion of this Application while engaging in Permitted
        Purposes, you agree to keep on these copies all of our copyright and other proprietary notices as they appear on
        this Website or in the application.
      </p>
      <p className={styles.paragraph}>
        Subject to these Terms, we also grant you a limited, personal, non-exclusive, non-transferable license to
        download, install and operate any plug-ins, software, information, or other content that we may have
        specifically identified within the Application and on our Website as available for download solely in connection
        with being a User. If such Downloadable Items are subject to additional terms, they will be provided to you upon
        download.
      </p>
      <p className={styles.paragraph}>
        Our Service is not intended for and may not be used by individuals under 13 years of age. By registering for a
        Seriously Dogs account, you state that you are at least 18 years of age, and you are responsible for ensuring
        that all Seriously Dogs Users and Owners are at least 13 years old. Creating an account with false information
        is a violation of our Terms, including creating an account on behalf of others or persons under 18.
      </p>

      <strong>Account Set-up</strong>
      <p className={styles.paragraph}>
        You need to set up a Seriously Dogs account to use the Application. You may establish either a free account (
        "Free Account ") or a paid account ( "Paid Account "). Seriously Dogs allows you to set up one Free Account per
        e-mail address. Seriously Dogs reserves the right to terminate any Free Accounts that have been inactive for
        over 12 months.
      </p>
      <p className={styles.paragraph}>
        When you create an account, we collect registration-related information, including your e-mail address and your
        name. Once you submit the required registration information, we alone will determine whether to approve your
        proposed account. If approved, you will be sent an e-mail detailing how to complete your registration. For so
        long as you use the account, you agree to provide true, accurate, current, and complete information that can be
        accomplished by contacting us to make any relevant changes to your account. You are responsible for complying
        with these Terms when you access this Application, whether directly or through any account you may set up
        through or on the Application or our Website. Because it is your account, it is your job to obtain and maintain
        all equipment and services needed for access to and use of this Application and paying related charges. You
        agree to choose a strong and secure password(s) and maintain the confidentiality of your password(s), including
        any password of a third-party site that we may allow you to use to access this Website. Should you believe your
        password or security for this Application has been breached in any way, you must immediately notify us at{' '}
        <a href='mailto:info@seriously-dogs.com'>
          <span className='s3'>info@seriously-dogs.com</span>
        </a>
        .
      </p>
      <p className={styles.paragraph}>
        Seriously Dogs or our billing processors collect your billing information when you take out a Paid Account. All
        information is protected in accordance with our Privacy Policy.
      </p>
      <p className={styles.paragraph}>
        Your account belongs to you. If another party pays for your account for you to use (for example, paid and
        managed by your employer), the party paying for your account has the right to control it, manage it, and get
        reports on your use of the Paid Account. The party paying for your account does not have rights nor access to
        any separate, personal account(s) that are not paid for nor managed by that party.
      </p>

      <strong>Subscription Period and Subscription Fees</strong>
      <p className={styles.paragraph}>
        You agree to pay all applicable fees related to your use of this Application and additional products as
        described on our Website&apos;s pricing page. By providing a payment method, you expressly authorise us to
        charge the applicable fees on said payment method as well as taxes and other charges incurred thereto at regular
        intervals, all of which depend on your subscription and utilised services.
      </p>
      <p className={styles.paragraph}>
        The subscription period for Paid Accounts can be month-to-month, yearly, or another duration described on our
        Website&apos;s pricing page.
      </p>
      <p className={styles.paragraph}>
        Paid Accounts are subject to fees based upon the account type. For renewals, Seriously Dogs will automatically
        charge your payment method in the amount of the then-current fee for your account type, plus applicable taxes.
        Each Renewal Term may include a price increase of the Consumer Price Index ( "CPI ") + 3% unless we notify you
        of a different rate before each Renewal Term starts. All payments are non-refundable. To the extent that payment
        mechanisms are provided through third parties, you agree that Seriously Dogs will have no liability to you
        arising out of the acts or omissions of such third parties. If applicable, you agree to maintain current billing
        information.
      </p>
      <p className={styles.paragraph}>
        By creating a paid account, you consent to allow Seriously Dogs to charge your payment method directly or
        through its payment processors for the amounts due for your initial subscription period and additional
        subscription periods until your account is cancelled or terminated. Please print out these terms and conditions
        for your records.
      </p>

      <strong>Renewal/Cancellation</strong>
      <p className={styles.paragraph}>
        Paid Accounts will automatically renew for the same subscription period unless you cancel the account by the end
        of the then-current subscription period. You can cancel your account at any time by contacting User service at{' '}
        <a href='mailto:info@seriously-dogs.com'>
          <span className='s3'>info@seriously-dogs.com</span>
        </a>
        . Cancellation will be effective at the end of the last paid month. Please note that after you cancel your
        account, you will no longer be able to use the Application to its full performance, and you will not be entitled
        to a refund of any fees that you&apos;ve paid.
      </p>

      <strong>Legal Compliance</strong>
      <p className={styles.paragraph}>
        You agree to comply with all applicable domestic and international laws, statutes, ordinances, and regulations
        regarding your use of our Services. Seriously Dogs reserves the right to investigate complaints or reported
        violations of our Terms and to take any action we deem appropriate, including but not limited to cancelling your
        User account, reporting any suspected unlawful activity to law enforcement officials, regulators, or other third
        parties and disclosing any information necessary or appropriate to such persons or entities relating to your
        profile, e-mail addresses, usage history, posted materials, IP addresses, and traffic information, as allowed
        under our Privacy Policy. You agree to promptly notify Seriously Dogs if you are made aware of any unauthorised
        or illegal use of the Application.
      </p>

      <strong>Intellectual Property</strong>
      <p className={styles.paragraph}>
        Our Services may contain our service marks or trademarks as well as those of our affiliates or other companies,
        in the form of words, graphics, and logos, and any and all intellectual property provided to User or any
        Authorised User in connection with the Services ( "Seriously Dogs IP "). Your use of our Services does not
        constitute any right or license for you to use such service marks/trademarks. Our Application is also protected
        under international copyright laws. The copying, redistribution, use, or publication by you of any portion of
        our Application is strictly prohibited. Your use of our Services does not grant you ownership rights of any kind
        in our Application or Materials. Seriously Dogs reserves all rights not expressly granted to User in this
        Agreement. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this
        Agreement grants, by implication, waiver, estoppel, or otherwise, to User or any third party any intellectual
        property rights or other rights, title, or interest in or to the Seriously Dogs IP.
      </p>

      <strong>Notices and Message</strong>
      <p className={styles.paragraph}>
        By using the Services, you consent to receive e-mails and notifications from us. These e-mails or notifications
        may include notices about applicable fees and charges, transactional information and other information related
        to the Services. These e-mails or notifications are part of your relationship with us. By scheduling a meeting
        as an Owner with a Seriously Dogs User, you consent to receive transactional notifications related to the
        scheduled meeting (e.g. booking confirmations and reminders).
        <span className='Apple-converted-space'> </span>
      </p>
      <p className={styles.paragraph}>
        You agree that any notices, agreements, disclosures, or other communications that we send you electronically
        will satisfy any legal communication requirements, including that such communications be in writing. You agree
        to keep your contact information up to date and monitor your e-mail settings to ensure that you receive e-mails
        from us at your discretion and as necessary.
      </p>

      <strong>Links to Other Websites</strong>
      <p className={styles.paragraph}>
        Our Services may contain links to third party websites. These links are provided solely as a convenience to you.
        By linking to these websites, we do not create or have an affiliation with or sponsor such third-party websites.
        The inclusion of links within our Application or within the notes or chat section does not constitute any
        endorsement, guarantee, warranty, or recommendation of such third-party websites. Seriously Dogs has no control
        over third-party websites&apos; legal documents and privacy practices. By using them, you may be giving those
        third parties permission to use or control your information in ways Seriously Dogs would not. As such, you
        access any third-party websites at your own risk.
      </p>

      <strong>Chat</strong>
      <p className={styles.paragraph}>
        Certain areas of our Services allow you to interact with others ( "Chat "). The Chat may permit you to exchange
        feedback, information, data, text, software, messages, or other materials (each, a "Submission ") between Users
        and Owners. If you choose to provide a Submission, you agree that you are fully responsible for the content of
        the Submission. Seriously Dogs does not endorse, guarantee, warrant or recommend Submissions. Seriously Dogs has
        no control over the content of Submissions. When linking to third parties in a Submission, you may be giving
        those third parties permission to use or control your information in ways Seriously Dogs would not. As such, any
        access to third parties through Submissions is at your own risk.
      </p>
      <p className={styles.paragraph}>
        Any information that you post in a Chat might be read, collected, and used by others who access it. To request
        the removal of a Submission from a Chat, please contact{' '}
        <a href='mailto:info@seriously-dogs.com'>
          <span className='s3'>info@seriously-dogs.com</span>
        </a>
        . In some cases, we may not be able to remove your information, in which case we will let you know and explain
        why.
      </p>
      <p className={styles.paragraph}>
        You agree that you are solely responsible for all of your Submissions and that any such Submissions are
        considered both non-confidential and non-proprietary. If you use Chat, you also understand that others could see
        your Submissions. Seriously Dogs does not endorse, nor are we required to pre-screen your Submissions (although
        we might), and, like elsewhere on the Internet, Submissions from other people could offend you. Further, we do
        not guarantee that you will be able to edit or delete any Submission you have submitted.
      </p>
      <p className={styles.paragraph}>
        By submitting any Submission, you are promising us that you own all rights in your Submission, your Submission
        does not infringe on the rights of others, your Submission does not violate any laws or regulations, and your
        Submission is not in poor taste.
      </p>

      <strong>Unauthorised Activities</strong>
      <p className={styles.paragraph}>
        To be clear, we authorise your use of these Services only for Permitted Purposes. Any other use of these
        Services beyond the Permitted Purposes is prohibited and, therefore, constitutes unauthorised use which may
        result in the violation of various international copyright laws, the deletion of your Seriously Dogs account, or
        a block from the Services. Unless you have written permission from us stating otherwise, you are not authorised
        to use these Services in any of the following ways. (Note that these are examples only, and the list below is
        not a complete list of everything that you are not permitted to do):
      </p>
      <p className={styles.paragraph}>You are not allowed to use the Services:</p>
      <ul className='ul1'>
        <li className='li2'>To create a competing product, resell or broker the Services to any third party.</li>
        <li className='li2'>
          In a manner that modifies, publicly displays, publicly performs, reproduces, or distributes any of the
          Services;
        </li>
        <li className='li2'>
          In a manner that violates any relevant law or the Terms of this Agreement, including but not limited to
          anything illegal in the jurisdiction where the User, Owner and/or Authorised User is located;
        </li>
        <li className='li2'>To stalk, harass, spam, or harm another individual, organisation, or business;</li>
        <li className='li2'>To disclose information that you do not have permission to disclose;</li>
        <li className='li2'>
          To impersonate any person or entity, misstate your Personal Data, or otherwise misrepresent your affiliation
          with a person or entity;
        </li>
        <li className='li2'>
          To interfere with or disrupt the Services or servers or networks connected to the Services;
        </li>
        <li className='li2'>
          To use any data mining, robots, or similar data gathering or extraction methods in connection with the
          Services;
        </li>
        <li className='li2'>To disseminate any viruses, worms, or otherwise harmful code;</li>
        <li className='li2'>
          To attempt to gain unauthorised access to any portion of the Services or any other accounts, computer systems,
          or networks connected to the Services, whether through hacking, password mining, or any other means;
        </li>
        <li className='li2'>
          To submit or store Payment Card Industry data (such as credit card numbers) without using an authorised
          Seriously Dogs payment integration;
        </li>
        <li className='li2'>
          To disclose any types of information listed in the California User Records statute (Cal. Civ. Code &sect;
          1798.80(e)) or other relevant privacy regulations, including medical or health insurance information,
          education information, employment information, credit or debit card information, or any other financial
          information, driver&apos;s license, or other government identification number;
        </li>
        <li className='li2'>
          To disclose or collect any types of information considered to be protected classification characteristics
          under California or other relevant law, including Age (if 40 years or older), race, colour, ancestry, national
          origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex
          (including gender, gender identity, gender expression, pregnancy or childbirth, and related medical
          conditions), sexual orientation, veteran or military status, and genetic information (including familial
          genetic information);
        </li>
        <li className='li2'>
          To disclose or collect any information defined by relevant laws as biometric information, or any information
          that falls within the definition of "special categories of data " under EU Data Protection Laws, "protected
          health information " under California or other relevant law or regulations. "Special categories of data "
          include, for example, information about an individual&apos;s race, ethnic origin, politics, religion, trade
          union membership, genetics, biometrics, health, sex life, sexual orientation, or criminal records.
        </li>
      </ul>

      <strong>Indemnification</strong>
      <p className={styles.paragraph}>
        You alone are responsible for any violation of these Terms by you. You agree to indemnify and hold Seriously
        Dogs, its parents, subsidiaries, affiliates, officers, agents, employees, resellers or other partners, and
        licensors harmless from any claim, demand, loss, or damages, including reasonable attorneys&apos; fees ( "Losses
        "), resulting from any third-party claim, suit, action, or proceeding ( "Third-Party Claim ") that the User
        Data, or the use thereof in accordance with this Agreement, infringes or misappropriates such third party&apos;s
        intellectual property rights or violates any applicable law or regulation and any Third-Party Claims based on
        User&apos;s or any Authorised Users&apos; (i) negligence or wilful misconduct; (ii) use of the Services in a
        manner not authorised by this Agreement; (iii) use of the Services in combination with data, software, hardware,
        equipment, or technology not provided by Seriously Dogs or authorised by Seriously Dogs in writing; or (iv)
        modifications to the Services not made by Seriously Dogs, provided that User may not settle any Third-Party
        Claim against Seriously Dogs unless Seriously Dogs consents to such settlement, and further provided that
        Seriously Dogs will have the right, at its option, to defend itself against any such Third-Party Claim or to
        participate in the defence thereof by counsel of its own choice.
      </p>

      <strong>Warranty Disclaimer</strong>
      <p className={styles.paragraph}>
        The Services are provided "As Is, " and Seriously Dogs hereby disclaims all warranties, whether express,
        implied, statutory, or otherwise. Seriously Dogs specifically disclaims all implied warranties of
        merchantability, fitness for a particular purpose, title, and non-infringement, and all warranties arising from
        course of dealing, usage, or trade practice. Seriously Dogs makes no warranty of any kind that the Seriously
        Dogs IP, Services, or any products or results of the use thereof, will meet User&apos;s or any other
        person&apos;s requirements, operate without interruption, achieve any intended result, be compatible or work
        with any software, system, or other services, or be secure, accurate, complete, free of harmful code, or
        error-free. Seriously Dogs strictly disclaims all warranties concerning any third-party integrations.
      </p>
      <p className={styles.paragraph}>
        This means that we do not promise you that the Services are free of problems. Without limiting the generality of
        the previous, we make no warranty that the Services will meet your requirements or will be uninterrupted,
        timely, secure, or error-free, or that defects will be corrected. We make no warranty as to the results obtained
        or the accuracy or reliability of any information obtained through the Services. Whether oral or written, no
        advice or information obtained by you through the Services or from us or our subsidiaries/other affiliated
        companies will create any warranty. We disclaim all equitable indemnities.
      </p>

      <strong>Limitation of Liability</strong>
      <p className={styles.paragraph}>
        Seriously Dogs will not be liable to you or others for any damages resulting from your use of the Services or
        your displaying, copying, or downloading any documentation to or from the Website. In no event will Seriously
        Dogs be liable under or in connection with this agreement under any legal or equitable theory, including breach
        of contract, tort (including negligence), strict liability, and otherwise, for any: (a) consequential,
        incidental, indirect, exemplary, special, enhanced, or punitive damages; (b) increased costs, diminution in
        value or lost business, production, revenues, or profits; (c) loss of goodwill or reputation; (d) use, inability
        to use, loss, interruption, delay, or recovery of any data, or breach of data or system security; or (e) cost of
        replacement goods or services, in each case regardless of whether seriously dogs was advised of the possibility
        of such losses or damages or such losses or damages were otherwise foreseeable.
        <span className='Apple-converted-space'> </span>
      </p>
      <p className={styles.paragraph}>
        In no event will Seriously Dogs&apos; aggregate liability or Seriously Dogs&apos; indemnification obligations
        arising out of or related to this agreement under any legal or equitable theory, including breach of contract,
        tort (including negligence), strict liability, and otherwise exceed the greater of (i) the amount paid, if any,
        by you to Seriously Dogs in connection with these terms in the twelve (12) months prior to the action giving
        rise to liability or (ii) us &pound;100.00.
      </p>
      <p className={styles.paragraph}>
        This limitation of liability is part of the basis of the Agreement between you and Seriously Dogs and shall
        apply to all claims of liability, even if Seriously Dogs has been told of the possibility of any such damage,
        and even if these remedies fail their essential purpose.
      </p>

      <strong>Local Laws; Export Control</strong>
      <p className={styles.paragraph}>
        We control and operate the Services from our headquarters in the United Kingdom, and the entirety of the
        Services may not be appropriate or available for use in other locations. If you use the Services outside the
        United Kingdom, you are solely responsible for following applicable local laws.
      </p>
      <p className={styles.paragraph}>
        Seriously Dogs and you shall comply with all relevant export control laws and regulations. Without limiting the
        foregoing: (a) you acknowledge that the Services may be subject to the United Kingdom Export Administration
        Regulations; (b) you affirm that you are not, and you will not permit Seriously Dogs Users or Owners to access
        or use the Services in a United Kingdom embargoed country; and (c) you are responsible for complying with any
        local laws and regulations which may impact your right to import, export, access, or use the Services.
      </p>

      <strong>Dispute Resolution and Arbitration; Class Action Waiver</strong>

      <p className={styles.italic}>Please Read This Provision Carefully. It Affects Your Legal Rights.</p>
      <p className={styles.paragraph}>
        This provision facilitates the prompt and efficient resolution of any dispute that may arise between you and us.
        Effectively, then, "dispute " is given the broadest meaning enforceable by law and includes any claims against
        other parties relating to services or products provided or billed to you (such as our licensors, suppliers,
        dealers or third-party vendors) whenever you also assert claims against us in the same proceeding.
      </p>
      <p className={styles.paragraph}>
        This provision provides that all disputes between you and us will be resolved by binding arbitration because
        acceptance of these Terms constitutes a waiver of your right to litigate claims and all opportunity to be heard
        by a judge or jury. To be clear, there is no judge or jury in arbitration, and court review of an arbitration
        award is limited. The arbitrator must follow this Agreement and can award the same damages and relief as a court
        (including attorney&apos;s fees). You and Seriously Dogs agree that any and all disputes, as defined above,
        whether presently in existence or based on acts or omissions in the past or in the future, will be resolved
        exclusively and finally by binding arbitration rather than in court in accordance with this provision.
      </p>

      <strong>Pre-Arbitration Claim Resolution</strong>
      <p className={styles.paragraph}>
        For all disputes, you must first give us an opportunity to cure or resolve the dispute, which is first done by
        sending an e-mail to{' '}
        <a href='mailto:info@seriously-dogs.com'>
          <span className='s3'>info@seriously-dogs.com</span>
        </a>{' '}
        with the following information: (1) your name, (2) your address, (3) a written description of your claim, and
        (4) a description of the specific relief you seek. If we do not resolve the dispute within 45 days after
        receiving your notification, you may pursue your dispute in arbitration.
      </p>

      <strong>Arbitration Procedures</strong>
      <p className={styles.paragraph}>
        If this provision applies and the dispute is not resolved as provided above (Pre-Arbitration Claim Resolution),
        you or we may initiate arbitration proceedings. The arbitration will be commenced as an individual arbitration
        and will in no event be commenced as a className arbitration. This provision governs in the event it conflicts
        with the applicable arbitration rules. Under no circumstances will className action procedures or rules apply to
        the arbitration. Because these Services and these Terms concern interstate commerce, the Arbitration Act 1996
        governs the arbitrability of all disputes. However, the arbitrator will apply applicable substantive law
        consistent with the Arbitration Act 1996 and the applicable statute of limitations or condition precedent to
        suit. You or we may initiate arbitration in either Plymouth, the United Kingdom or the judicial district where
        you are located.<span className='Apple-converted-space'> </span>
      </p>

      <strong>Class Action Waiver</strong>
      <p className={styles.paragraph}>
        Except as otherwise provided in this provision, the arbitrator may not consolidate more than one person&apos;s
        claims and may not otherwise preside over any form of a className or representative proceeding or claims (such
        as a className action, consolidated action or private attorney general action) unless both you and Seriously
        Dogs specifically agree to do so following initiation of the arbitration.
      </p>

      <strong>Other</strong>
      <p className={styles.paragraph}>
        You understand and agree that by accepting this provision in these Terms, you and we are each waiving the right
        to a jury trial or a trial before a judge in a public court. If any clause within this provision (other than the
        Class Action Waiver clause above) is found to be illegal or unenforceable, that clause will be severed from this
        provision whose remainder will be given full force and effect. If the Class Action Waiver clause is found to be
        illegal or unenforceable, this entire provision will be unenforceable, and a court will decide the dispute. This
        provision will survive the termination of your account with us or our affiliates and your discontinued use of
        this Application. Notwithstanding any provision in this Agreement to the contrary, we agree that if we make any
        change to this provision (other than a change to the Notice Address), you may reject any such change and require
        us to adhere to the language in this provision if a dispute between us arises.
      </p>

      <strong>General Terms</strong>
      <p className={styles.paragraph}>
        We believe that direct communication resolves most issues. If we feel that you are not complying with these
        Terms, we will contact you. We may even provide you with recommended necessary corrective action(s) if possible.
        However, certain violations of these Terms, as determined by us, may require immediate termination of your
        access to this Application without prior notice to you.
      </p>
      <p className={styles.paragraph}>
        These Terms will be governed to the fullest extent permitted by UK law. Except for disputes subject to
        arbitration as described above, any disputes relating to these Terms or our Services will be heard in the courts
        located in the United Kingdom. Except as otherwise outlined in the dispute resolution provision, if any of these
        Terms are deemed inconsistent with applicable law, then such term(s) will be interpreted to reflect the
        parties&apos; intentions, and no other terms will be modified. By choosing not to enforce any of these Terms, we
        are not waiving our rights. Together with our Privacy Policy, these Terms contain the entire agreement between
        you and us and, therefore, supersede all prior or contemporaneous negotiations, discussions, or agreements
        between Seriously Dogs and you about the Services. Any definitions found in the Privacy Policy are hereby
        adopted by reference in this Agreement. The rights of Seriously Dogs under our Terms will survive the
        termination of this Agreement. No additional terms, conditions, consent, waiver, alteration, or modification
        shall be binding unless in writing and signed by both parties. Acceptance of any order by Seriously Dogs is
        expressly limited to the terms and conditions of this Agreement. Any proposed additional or inconsistent terms
        or conditions, including those in or accompanying any User proposal, any User purchase order, or other agreement
        or any other User document issued in connection with the sale or delivery of Products or Services is deemed by
        Seriously Dogs to be a material change and is objected to and rejected by Seriously Dogs. Seriously Dogs&apos;
        acceptance of any orders will not constitute acceptance of any terms and conditions contained therein.
      </p>
      <p className={styles.paragraph}>
        In no event shall Seriously Dogs be liable to User, or be deemed to have breached this Agreement, for any
        failure or delay in performing its obligations under this Agreement, if and to the extent such failure or delay
        is caused by any circumstances beyond Seriously Dogs&apos; reasonable control, including but not limited to acts
        of God, flood, fire, earthquake, explosion, war, terrorism, invasion, riot or other civil unrest, strikes,
        epidemics, pandemics, contractor difficulties, labour stoppages or slowdowns or other industrial disturbances,
        or passage of a law or any action taken by a governmental or public authority, including imposing an embargo.
        <span className='Apple-converted-space'> </span>
      </p>
      <p className={styles.paragraph}>
        Seriously Dogs may request to disclose your company as a User of Seriously Dogs (if you use Seriously Dogs for
        business purposes and/or use a business e-mail address as your Seriously Dogs User e-mail address) and may
        request your permission to use your company&apos;s name and logo on the Website and in Seriously Dogs&apos;
        promotional content in accordance with User&apos;s brand guidelines or related documentation as provided by
        User. If User agrees to the request, Seriously Dogs will be granted a revocable, non-exclusive,
        non-sublicensable, non-transferable, royalty-free right and license (the "Trademark License ") to use, display
        and reproduce such party&apos;s name, logo, trademarks and service marks (the "Trademarks "). Both acknowledge
        that the other party&apos;s Trademark is and will remain the exclusive property of such party.
      </p>

      <strong>Changes</strong>
      <p className={styles.paragraph}>
        We may amend our Terms at any time by publishing revised Terms on our Website and/or by sending an e-mail to the
        e-mail address you gave us. These Terms are current as of the Effective Date set forth above. If any changes are
        made to these Terms, we&apos;ll post them on this page, so please check back periodically. If you continue to
        use Seriously Dogs after those changes are in effect, you agree to the revised Terms. Therefore, it is important
        for you to periodically review our Terms to make sure you still agree to them. If you object to any such
        modifications, your sole recourse will be to cease using the Application. Seriously Dogs reserves the right to
        change any and all features of our Services, at any time, without notice.
      </p>

      <strong>Contact Us</strong>
      <p className={styles.paragraph}>
        If you have any questions about these Terms or otherwise need to contact us for any reason, you can reach us at{' '}
        <a href='mailto:info@seriously-dogs.com'>
          <span className='s3'>info@seriously-dogs.com</span>
        </a>
      </p>
      <p className={styles.paragraph}>Copyright Seriously Dogs 2021</p>
    </div>
  );
};

export default TermsOfUsePage;
