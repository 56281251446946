import React, { useEffect } from 'react';
import styles from './SignUpPage.module.scss';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../../../redux/actions/authActions';
import { RegisterType } from '../../../../redux/reducers/authReducer';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import RoundedButton from '../../../components/RoundedButton/RoundedButton';
import CustomField from '../../../components/CustomField/CustomField';
import { registrationSchema, validate } from '../../../../helpers/validationSchema';
import { StateType } from '../../../../redux/store';
import { resetErrorsState } from '../../../../redux/reducers/errorsReducer';

const SignUpPage = (): JSX.Element => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: StateType) => state.errors);

  useEffect(() => {
    if (error.message) {
      setTimeout(() => {
        dispatch(resetErrorsState());
      }, 5000);
    }
  }, [error]);

  const onSubmit = async (values: RegisterType) => {
    dispatch(register({ ...values, role: 'trainer', invite_code: '' }));
  };

  const initValues = { name: '', email: '', phone: '', showPhone: true, companyName: '', password: '' };

  return (
    <div className={styles.wrapper}>
      <h1>Dog trainer registration</h1>
      <Form
        onSubmit={onSubmit}
        initialValues={initValues}
        validate={(values) => {
          return validate({ ...initValues, ...values }, registrationSchema);
        }}
        render={({ handleSubmit, form, submitting, pristine, values, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <CustomField name='name' type='text' placeholder='Your Name' label={'Your Name'} isRequired />
              <CustomField
                name='email'
                type='email'
                placeholder='Email'
                label={'Email'}
                isRequired
                errorMessage={error.message}
              />
              <CustomField name='phone' type='tel' placeholder='Contact phone' label={'Contact phone'} isRequired />
              <div className={styles.toggleWrapper}>
                <Field
                  name='showPhone'
                  component={({ input, meta }) => {
                    return (
                      <ToggleButton
                        active={values.showPhone}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {}}
                        inputProps={input}
                      />
                    );
                  }}
                  type='checkbox'
                />

                <span>Show phone in my profile</span>
              </div>
              <CustomField
                name='companyName'
                type='text'
                placeholder='Company Name'
                label={'Company Name'}
                isRequired
              />
              <CustomField name='password' type='password' placeholder='Password' label={'Password'} isRequired />
              <div className='buttons'>
                <RoundedButton
                  onClick={() => {
                    form.submit();
                  }}
                  disabled={submitting || pristine}
                  text={'Finish registration'}
                />
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default SignUpPage;
