import React, { useState } from "react";
import styles from "./Modal.module.scss";

type PropsType = {
  isActive?: boolean;
};

const Modal = ({ isActive }: PropsType): JSX.Element => {
  return (
    <div className={`${styles.modal} ${isActive ? styles.show : ""}`}>
      Sorry, this page made for Dog trainers.Please use the Seriously Dog
      application instead
    </div>
  );
};

export default Modal;
