import { createAsyncThunk } from '@reduxjs/toolkit';
import { user } from '../../api/user';

export const resetPassword = createAsyncThunk('user/resetPassword', async (values: { email: string }, thunkAPI) => {
  try {
    const data = await user.resetPassword(values);
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const setNewPassword = createAsyncThunk(
  'user/setNewPassword',
  async (values: { password: string; confirmCode: string }, thunkAPI) => {
    try {
      const data = await user.setNewPassword(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
