import React, { useState } from 'react';
import styles from './Loader.module.scss';

type PropsType = {
  isLoading?: boolean;
  notTransparent?: boolean;
};

const Loader = ({ isLoading = false, notTransparent }: PropsType): JSX.Element => {
  return (
    <div
      className={`${styles.wrapper} ${isLoading ? styles.isLoading : ''}  ${
        notTransparent ? styles.notTransparent : ''
      }`}
    >
      <div className={styles.lds_roller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
