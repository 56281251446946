import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import RoundedButton from '../../../components/RoundedButton/RoundedButton';
import InviteCode from './InviteCode/InviteCode';
import Stripe from './Stripe/Stripe';

import { getStripeAccount, createStripeAccount } from '../../../../redux/actions/stripeActions';
import { register } from '../../../../redux/actions/authActions';
import { RegisterType } from '../../../../redux/reducers/authReducer';
import { DispatchType, StateType } from '../../../../redux/store';
import styles from './ProfilePage.module.scss';
import Membership from './Membership/Membership';
import {
  getSubscriptionsPlan,
  getSubscription,
} from "../../../../redux/actions/subscriptionActions";
import { ResponseSubscriptionsPlan } from '../../../../redux/reducers/subscriptionsReducer';
import { UserType } from '../../../../redux/reducers/userReducer';

type TabComponentType = {
  activeButton: number;
  subscriptionPlans: Array<ResponseSubscriptionsPlan>;
  user: UserType;
  code: string;
};
const TabComponent = ({ activeButton, subscriptionPlans, user, code }: TabComponentType): JSX.Element => {
  switch (activeButton) {
    case 0:
      return <Membership {...{ subscriptionPlans }} {...{ user }} />;
    case 1:
      return <Stripe />;
    case 2:
      return <InviteCode code={code} />;
    default:
      return <InviteCode code={code} />;
  }
};

const ProfilePage = (): JSX.Element => {
  const [activeButton, setActiveButton] = useState(0);

  const {
    trainer: { code, ...props },
  } = useSelector((state: StateType) => state.trainer);
  const { user, isLoading } = useSelector((state: StateType) => state.user);
  const { subscriptionPlans } = useSelector(
    (state: StateType) => state.subscriptions
  );

  const dispatch = useDispatch<DispatchType>();

  useEffect(() => {
    dispatch(getSubscriptionsPlan({}));
    dispatch(getSubscription({}));
  }, []);

  const buttonsArr: Array<{ id: number; text: string }> = [
    { id: 0, text: "membership" },
    { id: 1, text: "stripe" },
    { id: 2, text: "invitation code" },
  ];

  return (
    <div className={styles.wrapper}>
      {/* <h1>User Page</h1> */}
      <div className={styles.buttons}>
        {buttonsArr.map(({ text, id }, index) => {
          return (
            <RoundedButton
              isActive={index === activeButton}
              key={`${index}`}
              {...{ text }}
              onClick={() => {
                setActiveButton(index);
              }}
            />
          );
        })}
      </div>
      <div className={styles.content}>
        <TabComponent
          {...{ user }}
          {...{ subscriptionPlans }}
          {...{ code }}
          {...{ activeButton }}
        />
      </div>
    </div>
  );
};

export default ProfilePage;
