import { RegisterType } from '../redux/reducers/authReducer';
import { LoginValuesType } from '../redux/reducers/userReducer';
import { instance } from './api';

export const trainer = {
  getTrainer: async () => {
    const { data } = await instance.get('/trainerProfile');
    return data;
  },
  getTrainers: async (values: LoginValuesType) => {
    const { data } = await instance.post('/trainersProfiles');
    return data;
  },
};
