import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getStripeAccount,
  createStripeAccount,
} from "../actions/stripeActions";
import {
  cancelSubscription,
  resumeSubscription,
} from "../actions/subscriptionActions";

type ResponseStripeType = {
  object: string;
  created: number;
  expiresAt: number;
  url: string;
};

type StripeStateType = {
  createAccountLink: string;
  accountLink: string;
  returnLink: string;

  error: string | null;
  isLoading: boolean;
};

const defaultState: StripeStateType = {
  createAccountLink: "",
  accountLink: "",
  returnLink: "",

  error: null,
  isLoading: false,
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState: defaultState,
  reducers: {
    resetStripe: () => {
      return {
        ...defaultState,
      };
    },
    setLoadingStripe: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
  extraReducers: {
    [getStripeAccount.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [getStripeAccount.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ResponseStripeType>
    ) => {
      if (typeof payload === "string") {
        state.accountLink = payload;
      }
      if (typeof payload === "object" && !!payload?.url) {
        state.accountLink = payload.url;
      }
      state.isLoading = false;
    },
    [getStripeAccount.rejected.toString()]: (
      state,
      { payload }: PayloadAction<{ message: string }>
    ) => {
      state.error = payload.message;
      state.isLoading = false;
    },
    [createStripeAccount.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [createStripeAccount.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ResponseStripeType>
    ) => {
      if (typeof payload === "string") {
        state.createAccountLink = payload;
      }
      if (typeof payload === "object" && !!payload?.url) {
        state.createAccountLink = payload.url;
      }
      state.isLoading = false;
    },
    [createStripeAccount.rejected.toString()]: (
      state,
      { payload }: PayloadAction<{ message: string }>
    ) => {
      state.error = payload.message;
      state.isLoading = false;
    },
    [resumeSubscription.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [resumeSubscription.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ResponseStripeType>
    ) => {
      state.isLoading = false;
    },
    [resumeSubscription.rejected.toString()]: (
      state,
      { payload }: PayloadAction<{ message: string }>
    ) => {
      state.error = payload.message;
      state.isLoading = false;
    },
    [cancelSubscription.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [cancelSubscription.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ResponseStripeType>
    ) => {
      state.isLoading = false;
    },
    [cancelSubscription.rejected.toString()]: (
      state,
      { payload }: PayloadAction<{ message: string }>
    ) => {
      state.error = payload.message;
      state.isLoading = false;
    },
  },
});

const stripeReducer = stripeSlice.reducer;

export const { resetStripe, setLoadingStripe } = stripeSlice.actions;
export default stripeReducer;
