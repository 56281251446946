import React from 'react';
import styles from './AuthPage.module.scss';

import BaseLayout from '../../layout/BaseLayout/BaseLayout';
import SignUpPage from './SignUpPage/SignUpPage';
import LoginPage from './LoginPage/LoginPage';
import { Route, Redirect } from 'react-router-dom';
import ForgotPage from './ForgotPage/ForgotPage';
import ProfilePage from './ProfilePage/ProfilePage';

const AuthPages = (): JSX.Element => {
  return (
    <>
      <Route path='/auth/sign-up' exact render={() => <SignUpPage />} />
      <Route path='/auth/login' exact render={() => <LoginPage />} />
      <Route path='/auth/forgot-password' exact render={() => <ForgotPage />} />
      {/*   <Route path='/user/profile' exact render={() => <ProfilePage />} /> */}

      <Route
        exact
        path={'/'}
        component={() => {
          return <Redirect to={'/auth/login'} />;
        }}
      />
      {/*  <Redirect from={'*'} to={'/auth/login'} /> */}
    </>
  );
};

export default AuthPages;
