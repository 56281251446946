import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { StateType } from '../../../redux/store';
import Content from '../../components/Content/Content';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './BaseLayout.module.scss';

type Props = {
  children?: React.ReactNode;
};

const BaseLayout = ({ children }: Props): JSX.Element => {
  const route = useLocation().pathname;
  const history = useHistory();

  const [showHeader, setShowHeader] = useState(false);
  const localToken = localStorage.getItem('token');
  const { token } = useSelector((state: StateType) => state.user);

  const loginedUser = token || localToken;

  useEffect(() => {
    if (!route.includes('login')) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }

    if (loginedUser && route.includes('auth')) {
      // history.push('/user/profile');
    } else if (!loginedUser && route.includes('user')) {
      history.push('/auth/login');
    }
  }, [route, loginedUser]);

  return (
    <div className={`${styles.BaseLayout} ${showHeader ? styles.withHeader : ''}`}>
      {showHeader && <Header />}
      <Content>{children}</Content>
      {/* <Footer /> */}
    </div>
  );
};

export default BaseLayout;
