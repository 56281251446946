import React from 'react';
import { Route } from 'react-router-dom';

import AboutUsPage from './AboutUsPage';
import TermsOfUsePage from './TermsOfUse';
import PrivacyPolicyPage from './PrivacyPolicy';
import BaseLayout from '../../layout/BaseLayout/BaseLayout';

const StaticPages = (): JSX.Element => (
  <>
    <Route path='/about-us' exact render={() => <AboutUsPage />} />
    <Route path='/terms-of-use' exact render={() => <TermsOfUsePage />} />
    <Route path='/privacy-policy' exact render={() => <PrivacyPolicyPage />} />
  </>
);

export default StaticPages;
