import { instance } from "./api";

export const stripe = {
  getStripeAccount: async () => {
    const { data } = await instance.get("/user/stripe");
    return data;
  },
  createStripeAccount: async () => {
    const { data } = await instance.post("/user/stripe");
    return data;
  },
};
