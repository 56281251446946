import { subscription } from './../api/subscription';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import authReducer from './reducers/authReducer';
import userReducer from './reducers/userReducer';

import alertReducer from './reducers/alertReducer';
import errorsReducer from './reducers/errorsReducer';
import trainerReducer from './reducers/trainerReducer';
import stripeReducer from './reducers/stripeReducer';
import subscriptionsReducer from './reducers/subscriptionsReducer';

const middleware = [...getDefaultMiddleware({ serializableCheck: false })];

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    alert: alertReducer,
    errors: errorsReducer,
    trainer: trainerReducer,
    stripe: stripeReducer,
    subscriptions: subscriptionsReducer,
  },
  middleware,
});

export type DispatchType = typeof store.dispatch;
export type StateType = ReturnType<typeof store.getState>;

export default store;
