import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTrainerProfile } from '../actions/trainerActions';

export type SchemeColorsType = 'main' | 'mainLighter' | 'alternative' | 'background' | 'darkText' | 'lightText';

export type ColorSchemeType = {
  [key in SchemeColorsType]: string;
};

type TrainerType = {
  code: '';
  currency: string;
  id: number;
  image: null | string;
  scheme: null | ColorSchemeType;
  userId: number;
};

type TrainerStateType = {
  trainer: any;
  isLoading: boolean;
};

const defaultState: TrainerStateType = {
  trainer: {
    code: '',
    currency: '',
    id: 0,
    image: '',
    scheme: null,
    userId: 0,
  },
  isLoading: false,
};

const trainerSlice = createSlice({
  name: 'trainer',
  initialState: defaultState,
  reducers: {},
  extraReducers: {
    [getTrainerProfile.pending.toString()]: (state, action) => {
      state.isLoading = true;
    },
    [getTrainerProfile.fulfilled.toString()]: (state, { payload }: PayloadAction<TrainerType>) => {
      state.trainer = payload;
      state.isLoading = false;
    },
    [getTrainerProfile.rejected.toString()]: (state, action) => {
      state.trainer = {};
      state.isLoading = false;
    },
  },
});

const trainerReducer = trainerSlice.reducer;

export const {} = trainerSlice.actions;
export default trainerReducer;
