import { createAsyncThunk } from "@reduxjs/toolkit";
import { stripe } from "../../api/stripe";

export const getStripeAccount = createAsyncThunk(
  "stripe/getStripeAccount",
  async ({}: any, thunkAPI) => {
    try {
      const { data } = await stripe.getStripeAccount();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createStripeAccount = createAsyncThunk(
  "stripe/createStripeAccount",
  async ({}: any, thunkAPI) => {
    try {
      const { data } = await stripe.createStripeAccount();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
