import { instance } from "./api";

export const subscription = {
  getSubscriptionsPlan: async () => {
    const { data } = await instance.get("/subscription/plans");
    return data;
  },
  getSubscription: async () => {
    const { data } = await instance.get("/subscribe");
    return data;
  },
  setSubscription: async (value: {
    payment_method_id: number | string | null;
    plan_id: number | string;
  }) => {
    const { data } = await instance.post("/subscribe", value);
    return data;
  },
  cancelSubscription: async () => {
    const { data } = await instance.post("/cancelSubscription");
    return data;
  },
  resumeSubscription: async () => {
    const { data } = await instance.post("/resumeSubscription");
    return data;
  },
};
