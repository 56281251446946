import { subscription } from "./../../api/subscription";
import {
  cancelSubscription,
  getSubscriptionsPlan,
  setSubscription,
  getSubscription,
} from "./../actions/subscriptionActions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ResponseSubscriptionsPlan = {
  active: boolean;
  aggregateUsage: null | any;
  amount: number;
  amountDecimal: string;
  billingScheme: string;
  created: number;
  currency: string;
  id: string;
  interval: string;
  intervalCount: number;
  livemode: false;
  metadata: Array<any>;
  nickname: null | any;
  object: string;
  product: {
    active: boolean;
    attributes: Array<any>;
    created: number;
    description: null | string;
    id: string;
    images: Array<any>;
    livemode: boolean;
    metadata: Array<any>;
    name: string;
    object: string;
    packageDimensions: null | any;
    shippable: null | any;
    statementDescriptor: null | any;
    taxCode: null | any;
    type: string;
    unitLabel: null | any;
    updated: number;
    url: null | any;
  };
  tiersMode: null;
  transformUsage: null;
  trialPeriodDays: null;
  usageType: string;
};

type SubscriptionsStateType = {
  subscriptionPlans: Array<ResponseSubscriptionsPlan>;
  intentSubscription: any;
  error: string | null;
  isLoading: boolean;
  isSubscribed: boolean;
};

const defaultState: SubscriptionsStateType = {
  subscriptionPlans: [],
  intentSubscription: {},
  error: null,
  isLoading: false,
  isSubscribed: false,
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: defaultState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    setSubscriptionError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [getSubscriptionsPlan.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [getSubscriptionsPlan.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<Array<ResponseSubscriptionsPlan>>
    ) => {
      state.subscriptionPlans = payload;
      // state.accountLink = payload.url;
      // state.returnLink = payload.returnLink;
      state.isLoading = false;
    },
    [getSubscriptionsPlan.rejected.toString()]: (
      state,
      { payload }: PayloadAction<{ message: string }>
    ) => {
      state.error = payload.message;
      state.isLoading = false;
    },
    [setSubscription.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
      state.isSubscribed = false;
    },
    [setSubscription.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.isSubscribed = true;
    },
    [setSubscription.rejected.toString()]: (state, { payload }) => {
      state.error = payload.message;
      state.isLoading = false;
    },
    [cancelSubscription.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [cancelSubscription.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
    },
    [cancelSubscription.rejected.toString()]: (state, { payload }) => {
      state.error = payload.message;
      state.isLoading = false;
    },
    [getSubscription.pending.toString()]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [getSubscription.fulfilled.toString()]: (state, { payload }) => {
      state.intentSubscription = payload;
      state.isLoading = false;
    },
    [getSubscription.rejected.toString()]: (state, { payload }) => {
      state.error = payload.message;
      state.isLoading = false;
    },
  },
});

const subscriptionsReducer = subscriptionsSlice.reducer;

export const { resetError, setSubscriptionError } = subscriptionsSlice.actions;
export default subscriptionsReducer;
