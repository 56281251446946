export const setArrayForCarusel = (
  array: Array<any> = [],
  size: number = 4
): Array<any> => {
  let subarray = [];

  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    subarray[i] = array.slice(i * size, i * size + size);
  }
  return subarray;
};

export const getTimeFromMins = (mins: number | string) => {
  let hours: number | string = Math.trunc(+mins / 60),
    minutes: number | string = +mins % 60;

  hours = hours < 10 ? (hours = "0" + hours) : hours;
  minutes = minutes < 10 ? (minutes = "0" + minutes) : minutes;

  return `${hours}:${minutes}`;
};

export const stringLimiter = (string: string, max = 300) => {
  if (string && string.length > max) {
    return `${string.substr(0, max - 1)}...`;
  } else {
    return string;
  }
};

export const capitalizeFirstLetter = (el: string) => {
  return el.slice(0, 1).toUpperCase() + el.slice(1);
};

// export const photoUserErrHandler = (userPhoto: string) => {
//   return `${
//     process.env.REACT_APP_SERVER_HOST
//   }/storage/photo/${userPhoto},${require(`../assets/svg/liveClasses/user.svg`)}`;
// };

export const setFullAmount = ({
  amount,
  currency,
  interval,
}: {
  amount: number;
  currency: string;
  interval: string;
}) => {
  if (interval === "month") {
    return `${currency.toUpperCase()}${(amount / 100).toFixed(
      2
    )}/${interval}, ${currency.toUpperCase()}${((amount / 100) * 12).toFixed(
      2
    )}/year`;
  } else if (interval === "year") {
    return `${currency.toUpperCase()}${(amount / 100 / 12).toFixed(
      2
    )}/month, ${currency.toUpperCase()}${(amount / 100).toFixed(
      2
    )}/${interval}`;
  } else {
    return `${currency.toUpperCase()}${amount}/${interval}`;
  }
};
