import React, { useState, FormEvent, useEffect } from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  PaymentElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styles from "./ModalStripe.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSubscription } from "../../../redux/actions/subscriptionActions";
import { getUser } from "../../../redux/actions/authActions";
import { DispatchType, StateType } from "../../../redux/store";
import RoundedButton from "../RoundedButton/RoundedButton";
import {
  resetError,
  setSubscriptionError,
} from "../../../redux/reducers/subscriptionsReducer";
import Loader from "../Loader/Loader";

type PropsType = {
  activePlanId: string;
  closeModal: Function;
  isActive: boolean;
};

const CheckoutForm = ({ activePlanId, closeModal }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisable] = useState(false);
  // const [isSubscribe, setIsSubscribe] = useState(false);
  const { error, isLoading, isSubscribed } = useSelector(
    (state: StateType) => state.subscriptions
  );

  const dispatch = useDispatch<DispatchType>();

  stripe?.elements({ locale: "en-GB" });

  useEffect(() => {
    if (!!error) {
      setTimeout(() => {
        dispatch(resetError());
        disabled && setDisable(false);
      }, 4000);
    }
  }, [error]);

  useEffect(() => {
    if (!!isSubscribed) {
      dispatch(getUser({}));
      closeModal();
      disabled && setDisable(false);
    }
  }, [isSubscribed]);

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();
      setDisable(true);
      if (!stripe || !elements) {
        return;
      }

      const card: any = await elements.getElement(CardElement);

      await stripe
        .createPaymentMethod({
          type: "card",
          card,
        })
        .then((res) => {
          const cardId = res.paymentMethod?.id;

          if (res?.error?.message) {
            dispatch(setSubscriptionError(res.error.message));
            return;
          } else if (cardId && activePlanId) {
            dispatch(
              setSubscription({
                payment_method_id: cardId,
                plan_id: activePlanId,
              })
            ).then((res) => {
              if (
                res?.payload?.success &&
                (res?.payload?.clientSecret || res?.payload?.data?.clientSecret)
              ) {
                stripe
                  .confirmCardPayment(
                    res?.payload?.clientSecret ||
                      res?.payload?.data?.clientSecret,
                    {
                      payment_method: { card: card },
                    }
                  )
                  .then(function (result) {
                    if (result.error) {
                      dispatch(setSubscriptionError(result.error.message));
                      return;
                    } else {
                      setTimeout(() => {
                        dispatch(getUser({}));
                        closeModal();
                      }, 1000);
                    }
                    setDisable(false);
                  });
              }
            });
          }
        })
        .catch((err) => {
          setDisable(false);
        });
    } catch (error) {
      setDisable(false);
    }
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          fontSize: "16px",
        },
        borderColor: "red",
        borderWidth: "1px",
        borderRadius: "6px",
        textAlign: "center",
        backgroundColor: "transparent",
        padding: "50px",
      },
      invalid: {
        color: "#f44336",
        iconColor: "#f44336",
      },
    },
    hidePostalCode: true,
  };
  return (
    <form
      onSubmit={handleSubmit}
      className={`${styles.cardElement} ${!!error ? styles.errorElement : ""}`}
    >
      <Loader isLoading={isLoading} />
      <CardElement
        className={styles.cardNumber}
        options={CARD_ELEMENT_OPTIONS}
      />
      <p className={`${!!error ? styles.error : ""}`}>{error}</p>
      <RoundedButton
        disabled={!stripe || disabled}
        onClick={() => {}}
        text={"Submit"}
        submitType
      />
    </form>
  );
};

const ModalStripe = ({
  activePlanId,
  closeModal,
  isActive,
}: PropsType): JSX.Element => {
  const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY), {
    locale: "en-GB",
  });

  const { isLoading } = useSelector((state: StateType) => state.subscriptions);

  return (
    <div
      className={`${styles.wrapper} ${isActive ? styles.show : ""}`}
      onClick={() => (isLoading ? undefined : closeModal())}
    >
      <div
        className={`${styles.modal}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Elements stripe={stripePromise}>
          <CheckoutForm {...{ activePlanId }} {...{ closeModal }} />
        </Elements>
      </div>
    </div>
  );
};

export default ModalStripe;
